import { Alert, Col, Row, Space } from 'antd';
import {
  FloorplanGeoPersonalizationPanel,
  PersonalizationSteps,
  usePersonalizationSteps,
} from '../Personalization.Details';
import { PersonalizationDetails } from './PersonalizationDetails';
import { FinalizationAlert } from './FinalizationAlert';
import { FinalizationResidents } from './FinalizationResidents';
import { FinalizeButton } from './FinalizeButton';
import { PaymentsPanel } from './Payments';
import { useProjectContext } from '@/views';
import { useGetProjectHomeInfoQuery } from '@/redux';
import { PersonalizationSummaryTotalBalance } from './PersonalizationSummaryTotalBalance';
import {
  PriceColumnsCheckboxGroup,
  usePriceColumnsCheckboxGroup,
} from './PriceColumnsCheckboxGroup';
import { useTranslation } from 'react-i18next';
import { isArray } from 'lodash';
import { DownloadPdfButton } from './DownloadPdfButton';
import { ResidentsCheckbox, useResidentsCheckbox } from './ResidentsCheckbox';

interface Props {
  homeId: string;
  communityId: string;
  projectId: string;
}

export function PersonalizationSummaryContent(props: Props) {
  const { homeId, projectId } = props;

  const { t } = useTranslation();

  const { isActive } = useProjectContext();

  var { data: projectHome } = useGetProjectHomeInfoQuery({
    id: projectId,
    homeId,
  });

  const steps = usePersonalizationSteps({ projectId, projectHome });
  const priceColumnsCheckboxGroup = usePriceColumnsCheckboxGroup();
  const residentsCheckBox = useResidentsCheckbox();
  const { showBaseCost, showResidentPrice } = priceColumnsCheckboxGroup;
  const { showResidents } = residentsCheckBox;
  const selectedStepId = isArray(steps.selected)
    ? steps.selected[0]
    : steps.selected;

  const selectedStep = steps.steps.find((x) => x.id === selectedStepId);

  const allPersonalizationsSelected = !selectedStep;

  if (!projectHome?.menuId) {
    return (
      <Alert
        type="info"
        description={t('personalization.floorplan.geo.noMenu')}
      />
    );
  }

  return (
    <Space direction="vertical" size="middle" className="w-100">
      <Row justify="space-between">
        <Space direction="horizontal">
          <PersonalizationSteps {...steps} />
          <DownloadPdfButton
            projectId={projectId}
            homeId={homeId}
            showBaseCost={showBaseCost}
            showResidentPrice={showResidentPrice}
            selectedStepId={selectedStep?.id}
            showResidentInfo={showResidents}
          />
        </Space>
        <Space>
          <ResidentsCheckbox {...residentsCheckBox} />
        </Space>
      </Row>

      {selectedStep?.id && isActive && (
        <FinalizeButton
          projectId={projectId}
          homeId={homeId}
          selectedStepId={selectedStep?.id}
        />
      )}

      {allPersonalizationsSelected && (
        <Col span={12}>
          <FinalizationAlert homeId={homeId} projectId={projectId} />
        </Col>
      )}
      <FloorplanGeoPersonalizationPanel
        homeId={homeId}
        projectId={projectId}
        selectedStepIds={selectedStep ? [selectedStep.id] : undefined}
        readonly
      />
      <PriceColumnsCheckboxGroup {...priceColumnsCheckboxGroup} />
      <PersonalizationDetails
        homeId={homeId}
        projectId={projectId}
        stepId={selectedStep?.id}
        showBaseCost={showBaseCost}
        showResidentPrice={showResidentPrice}
        className="mb-3"
      />
      <PaymentsPanel
        className="w-100"
        homeId={homeId}
        projectId={projectId}
        stepId={selectedStep?.id}
      />
      {allPersonalizationsSelected && (
        <PersonalizationSummaryTotalBalance
          homeId={homeId}
          projectId={projectId}
          showBaseCost={showBaseCost}
          showResidentPrice={showResidentPrice}
        />
      )}
      {projectHome?.isActive && (
        <FinalizationResidents
          projectId={projectId}
          homeId={homeId}
          selectedStepId={selectedStep?.id}
        />
      )}
    </Space>
  );
}
