import { SpecialRequestDto, SpecialRequestStatus } from '@/core';
import { useEnumTranslation } from '@/utils/useEnumTranslation';
import { Tag } from 'antd';

const statusColorMap: Record<SpecialRequestStatus, string> = {
  Drawing: 'geekblue',
  Price: 'cyan',
  ManagementApproved: 'lime',
  Clarify: 'orange',
  ConstructionApproved: 'green',
  Denied: 'volcano',
  ResidentDeclined: 'volcano',
  Pending: 'purple',
};

interface Props {
  item: SpecialRequestDto | undefined;
}

export function SpecialRequestStatusTag(props: Props) {
  const { item } = props;
  const et = useEnumTranslation();
  return item ? (
    <Tag className="me-0" color={statusColorMap[item.status]}>
      {et(SpecialRequestStatus, item?.status)}
    </Tag>
  ) : (
    <></>
  );
}
