import { guard } from '@/utils';
import i18next from 'i18next';
import React from 'react';

export interface ConfirmationArgs {
  onConfirm?: () => any;
  onCancel?: () => any;
  title?: React.ReactNode;
  body?: React.ReactNode;
  cancel?: React.ReactNode;
  confirm?: React.ReactNode;
  hideCancelButton?: boolean;
}

export type ConfirmationArgsOrConfirmFn = ConfirmationArgs | (() => any);
export type ConfirmationSubscriber = (
  args: ConfirmationArgs | undefined,
) => any;

class ConfirmationManager {
  private _subscriber!: ConfirmationSubscriber;

  /**
   * Add or replace current global confirmation subscriber
   * @param subscriber callback function
   */
  public _subscribe = (subscriber: ConfirmationSubscriber) => {
    guard.notNull(subscriber, 'subscriber');
    this._subscriber = subscriber;
  };

  /**
   *
   * @param argsOrOnConfirm onConfirm function or confirmation settings
   */
  public show = (argsOrOnConfirm: ConfirmationArgsOrConfirmFn) => {
    guard.notNull(argsOrOnConfirm, 'argsOrOnConfirm');
    const args = this.args(argsOrOnConfirm);
    this._subscriber && this._subscriber(args);
  };

  public delete(
    entityNameOrKey: string | { key: string },
    onConfirm: () => any,
  ) {
    const entityName =
      typeof entityNameOrKey === 'string'
        ? entityNameOrKey
        : i18next.t(entityNameOrKey.key);

    this.show({
      title: i18next.t<string>('confirmation.delete.title'),
      body: i18next.t<string>('confirmation.delete.body', { entityName }),
      onConfirm,
    });
  }

  public deactivate(
    entityNameOrKey: string | { key: string },
    onConfirm: () => any,
  ) {
    const entityName =
      typeof entityNameOrKey === 'string'
        ? entityNameOrKey
        : i18next.t(entityNameOrKey.key);

    this.show({
      title: i18next.t<string>('confirmation.deactivate.title'),
      body: i18next.t<string>('confirmation.deactivate.body', { entityName }),
      onConfirm,
    });
  }

  /**
   *  Hides global confirmation
   */
  public hide = () => {
    this._subscriber && this._subscriber(undefined);
  };

  private args = (argsOrOnConfirm: ConfirmationArgsOrConfirmFn) => {
    return typeof argsOrOnConfirm === 'function'
      ? { onConfirm: argsOrOnConfirm }
      : argsOrOnConfirm;
  };
}

export const confirmation = new ConfirmationManager();
