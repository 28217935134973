import {
  DefaultNumberSorting,
  Table,
  TabPanel,
  useDefaultTablePaging,
  useFilters,
  useSorting,
} from '@/components';
import { CommunitiesFilterParams } from '@/core';
import { CommunityState, useGetCommunitiesListQuery } from '@/redux';
import { useCommunityLogosColumns } from './useCommunityLogosColumns';
import { useMemo } from 'react';

export function CommunityLogosListPanel() {
  const { setTotal, paging, params } = useDefaultTablePaging();
  const {
    filters,
    onFiltersChange,
    resetFilters,
    stringFilterOptions,
    setStringFilterOptions,
  } = useFilters<CommunitiesFilterParams>(['name', 'number', 'clientName']);
  const { sorting, onSortingChange } =
    useSorting<CommunityState>(DefaultNumberSorting);
  const { currentData, isFetching } = useGetCommunitiesListQuery({
    clientId: undefined,
    deactivated: false,
    paging: params,
    filters,
    sorters: sorting,
  });

  const communities = useMemo(() => {
    if (currentData) {
      setStringFilterOptions(currentData.stringFilterOptions);
      setTotal(currentData.total);
    }
    return currentData?.items;
  }, [currentData, setStringFilterOptions, setTotal]);
  const columns = useCommunityLogosColumns(communities, stringFilterOptions);
  return (
    <TabPanel>
      <TabPanel.Body>
        <Table<CommunityState>
          name="communityLogos"
          loading={isFetching}
          columns={columns}
          dataSource={communities}
          rowKey={(row) => row.id}
          pagination={paging}
          onChange={(_, filters, sorters) => {
            onFiltersChange(filters);
            onSortingChange(sorters);
          }}
          onFiltersReset={resetFilters}
        />
      </TabPanel.Body>
    </TabPanel>
  );
}
