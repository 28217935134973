import { DeleteIconButton, EditIconButton, notify } from '@/components';
import { flippoClient, miscHttp } from '@/core';
import { useUpdateCommunityLogoMutation } from '@/redux';
import { UploadOutlined } from '@ant-design/icons';
import { Button, Space, Typography } from 'antd';
import { Image } from 'antd';
import { useEffect, useMemo, useRef, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { LazyImageCropper, Picker } from '../Users.General';
import styles from './CommunityLogo.module.scss';

interface LogoEditProps {
  communityId: string;
  reference?: string;
}

const { Text } = Typography;

function useLogoState(
  props: LogoEditProps & { onChange(reference: string): void },
) {
  const { onChange } = props;
  const [file, setFile] = useState<File | undefined>(undefined);
  const blob = useBlob(file);

  const onCancel = () => {
    setFile(undefined);
  };

  const onSave = (file: File) =>
    flippoClient
      .store(file)
      .then((reference) => onChange(reference))
      .then(() => setFile(undefined));

  return { blob, onSelect: setFile, onCancel, onSave };
}

function useBlob(file: File | undefined) {
  const previousBlobRef = useRef<string | undefined>(undefined);
  useEffect(() => cleanup, []);

  function cleanup() {
    previousBlobRef.current && URL.revokeObjectURL(previousBlobRef.current);
  }

  return useMemo(() => {
    cleanup();
    return file && (previousBlobRef.current = URL.createObjectURL(file));
  }, [file]);
}

function useHandleLogoChange(props: LogoEditProps) {
  const { communityId } = props;
  const [update] = useUpdateCommunityLogoMutation();
  return useCallback(
    async (logo?: string) =>
      await update({ logo, id: communityId })
        .unwrap()
        .then(() => notify.success.t('users.profile.saved')),
    [update, communityId],
  );
}

function useLogo(reference: string | undefined) {
  const [url, setUrl] = useState<string>();
  const [notFound, setNotFound] = useState<boolean>(!reference);

  function revokeBlobUrl() {
    if (url) {
      URL.revokeObjectURL(url);
    }
  }

  async function fetchLogo() {
    try {
      const blob = reference!.startsWith('logo-')
        ? await miscHttp.logo(reference!)
        : await flippoClient.fetch(reference!);
      setUrl(URL.createObjectURL(blob));
      setNotFound(false);
    } catch {
      setUrl(undefined);
      setNotFound(true);
    }
  }

  useEffect(() => {
    revokeBlobUrl();

    if (!reference) {
      return;
    }

    fetchLogo();

    return revokeBlobUrl;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reference]);

  return { url, notFound };
}

export function LogoUploadItem(props: LogoEditProps) {
  const { communityId, reference } = props;
  const { t } = useTranslation(undefined, {
    keyPrefix: 'summarySettings.communityLogos',
  });

  const change = useHandleLogoChange(props);

  const { blob, onSelect, onCancel, onSave } = useLogoState({
    reference: reference,
    onChange: change,
    communityId,
  });
  const { url } = useLogo(reference);
  return (
    <Space size="large">
      {url ? (
        <>
          <Image width={360} height={90} src={url} className={styles.image} />
          <Picker onSelected={onSelect}>
            <EditIconButton />
          </Picker>
          <DeleteIconButton
            entityName={t('title')}
            confirm
            stopPropagation
            onClick={() => change()}
          />
        </>
      ) : (
        <>
          <Picker onSelected={onSelect}>
            <Button icon={<UploadOutlined />}>{t('upload')}</Button>
          </Picker>
          <Text type="secondary">{t('format')}</Text>
        </>
      )}

      {blob && (
        <LazyImageCropper blob={blob} onCancel={onCancel} onSave={onSave} />
      )}
    </Space>
  );
}
