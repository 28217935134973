import {
  createEntireLoadLookupSource,
  LookupOption,
  LookupSource,
} from '@/components';
import {
  ProjectStepLookupState,
  RtkqSpin,
  useLazyGetProjectStepsQuery,
} from '@/redux';
import { Typography } from 'antd';
import { orderBy } from 'lodash';
import { useMemo } from 'react';

const { Text } = Typography;

function map(
  value: ProjectStepLookupState,
  includeProjectName?: ProjectNameIncludeType | false,
): LookupOption {
  return {
    label:
      includeProjectName === 'title' ? (
        `${value.projectName} - ${value.name}`
      ) : includeProjectName === 'subtitle' ? (
        <span>
          <div>
            <Text>{value.name}</Text>
          </div>
          <div>
            <Text type="secondary">{value.projectName}</Text>
          </div>
        </span>
      ) : (
        value.name
      ),
    value: value.id,
  };
}

type ProjectNameIncludeType = 'title' | 'subtitle';

export function useProjectStepLookupSource(
  projectIds?: string[],
  includeProjectName?: ProjectNameIncludeType | false,
): LookupSource {
  const [fetchList] = useLazyGetProjectStepsQuery();

  return useMemo(() => {
    const entireSource = createEntireLoadLookupSource({
      initial: (id: string) =>
        fetchList({ ids: projectIds, [RtkqSpin]: false }, true)
          .unwrap()
          .then((values) =>
            map(values.find((x) => x.id === id)!, includeProjectName),
          ),
      load: () =>
        fetchList({ ids: projectIds, [RtkqSpin]: false }, true)
          .unwrap()
          .then((values) =>
            orderBy(values, (x) => x.name.toLowerCase(), 'asc').map((x) =>
              map(x, includeProjectName),
            ),
          ),
    });

    return { ...entireSource, searchField: '_search' };
  }, [fetchList, includeProjectName, projectIds]);
}
