import { Role, SpecialRequestStatus } from '@/core';
import { useGetAllSpecialRequestStatusesQuery } from '@/redux';
import { useEnumTranslation } from '@/utils/useEnumTranslation';
import { Divider, Timeline } from 'antd';
import { isEmpty } from 'lodash';
import { Trans } from 'react-i18next';

interface Props {
  specialRequestId: string;
}

export function SpecialRequestStatusesList(props: Props) {
  const { specialRequestId } = props;

  const { data } = useGetAllSpecialRequestStatusesQuery({
    specialRequestId,
  });
  const et = useEnumTranslation();

  return (
    <div>
      {!isEmpty(data) && <Divider dashed />}
      {!isEmpty(data) && (
        <Timeline>
          {data?.map((value) => (
            <Timeline.Item>
              <Trans
                i18nKey="specialRequests.statusHistory"
                components={{ strong: <strong /> }}
                values={{
                  createdByName: value.createdByName,
                  createdByRole: et(Role, value.createdByRole),
                  createdAt: value.createdAt,
                  status: et(SpecialRequestStatus, value.status),
                }}
              />
            </Timeline.Item>
          ))}
        </Timeline>
      )}
    </div>
  );
}
