import {
  DeleteItemPriceDto,
  DeletePricingMarkupDto,
  ItemPriceGroupDto,
  PriceMatrixDto,
  PriceMatrixRowDto,
  pricingHttp,
  PricingSettingsMatrixDto,
  PricingSettingsMatrixRowDto,
  SaveItemPriceDto,
  SavePricingMarkupDto,
  SpecialRequestPriceMatrixRowDto,
  UpdatePricingMarkupDefaultsDto,
  UpdateSpecialRequestPriceMatrixRowDto,
  UpdateSpecialRequestPriceMatrixRowResultDto,
  SpecialRequestPriceMatrixDto,
  UpdateItemMenuItemPricingInfoDto,
  HomePriceMatrixRowDto,
  HomePriceMatrixDto,
  SaveHomeItemPriceDto,
  Paging,
  PriceMatrixFilterParams,
  HomePriceMatrixFilterParams,
  SpecialRequestPriceMatrixFilterParams,
} from '@/core';
import { rtkq, RtkqRequest } from '../rtkq';
import { api } from '../api';
import { SortResult } from '@/components';

export type PriceMatrixState = PriceMatrixDto;
export type PriceMatrixRowState = PriceMatrixRowDto;
export type SaveItemPriceArgs = SaveItemPriceDto;
export type DeleteItemPriceArgs = DeleteItemPriceDto;
export type SavePricingMarkupArgs = SavePricingMarkupDto;
export type DeletePricingMarkupArgs = DeletePricingMarkupDto;
export type UpdatePricingMarkupDefaultsArgs = UpdatePricingMarkupDefaultsDto;
export type PricingSettingsMatrixState = PricingSettingsMatrixDto;
export type PricingSettingsMatrixRowState = PricingSettingsMatrixRowDto;
export type SpecialRequestPriceMatrixState = SpecialRequestPriceMatrixDto;
export type SpecialRequestPriceMatrixRowState = SpecialRequestPriceMatrixRowDto;
export type UpdateSpecialRequestPriceMatrixRowArgs =
  UpdateSpecialRequestPriceMatrixRowDto & {
    projectIds?: string[];
    communityId: string;
    paging: Paging;
  };
export type UpdateSpecialRequestPriceMatrixRowResult =
  UpdateSpecialRequestPriceMatrixRowResultDto;
export type UpdateItemMenuItemPricingInfoArgs =
  UpdateItemMenuItemPricingInfoDto & {
    communityId: string;
  };
export type HomePriceMatrixRowState = HomePriceMatrixRowDto;
export type HomePriceMatrixState = HomePriceMatrixDto;
export type SaveHomeItemPriceArgs = SaveHomeItemPriceDto;

function updatePriceMatrixData(
  matrix: PriceMatrixState,
  updatedGroup: ItemPriceGroupDto,
) {
  for (const row of matrix.rows.items) {
    const updated = updatedGroup.prices.find(
      (x) =>
        /* eslint-disable eqeqeq */
        x.itemId == row.itemId &&
        x.floorplanId == row.floorplan?.id &&
        x.roomId == row.room?.id,
      // eslint-enable
    );

    if (updated) {
      row.prices = updated.value;
    }
  }
}

function removePriceMatrixRowPrices(
  matrix: PriceMatrixState,
  args: DeleteItemPriceArgs,
) {
  const row = matrix.rows.items.find(
    (x) =>
      x.itemId === args.itemId &&
      x.floorplan?.id === args.floorplanId &&
      x.room?.id === args.roomId,
  )!;

  row.prices = undefined;
}

export const pricingApi = api.injectEndpoints({
  endpoints: (build) => ({
    getPriceMatrix: build.query<
      PriceMatrixState,
      RtkqRequest<{
        communityId: string;
        menuId: string;
        paging: Paging;
        filters?: PriceMatrixFilterParams;
      }>
    >({
      queryFn: async (args) => {
        return rtkq(args).exec(() =>
          pricingHttp.getMatrix(
            args.communityId,
            args.menuId,
            args.paging,
            args.filters,
          ),
        );
      },
      providesTags: (result, _, { communityId, menuId }) => {
        const { rows } = result ?? {};

        return [
          { type: 'itemMenu' as const, id: menuId },
          { type: 'pricing' as const, id: communityId + menuId },
          { type: 'pricing-markup' as const, id: communityId },
          ...(rows?.items || []).map((x) => ({
            type: 'item' as const,
            id: x.itemId,
          })),
          ...(rows?.items || []).flatMap((x) =>
            x.categories.map((c) => ({ type: 'category' as const, id: c.id })),
          ),
          ...(rows?.items || []).map((x) => ({
            type: 'floorplan' as const,
            id: x.floorplan?.id ?? 'none',
          })),
          ...(rows?.items || [])
            .filter((x) => x.room != null)
            .map((x) => ({ type: 'room' as const, id: x.room!.id })),
        ];
      },
    }),

    saveItemPrice: build.mutation<
      ItemPriceGroupDto,
      RtkqRequest<{
        itemPrice: SaveItemPriceArgs;
        paging: Paging;
        filters?: PriceMatrixFilterParams;
      }>
    >({
      queryFn: (args) => {
        return rtkq(args).exec(() => pricingHttp.saveItemPrice(args.itemPrice));
      },
      invalidatesTags: ['personalization-item-price'],
      onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
        const { data: group } = await queryFulfilled;
        dispatch(
          pricingApi.util.updateQueryData(
            'getPriceMatrix',
            {
              communityId: args.itemPrice.communityId,
              menuId: args.itemPrice.menuId,
              paging: args.paging,
              filters: args.filters,
            },
            (draft) => {
              updatePriceMatrixData(draft, group);
            },
          ),
        );
      },
    }),

    deleteItemPrice: build.mutation<
      ItemPriceGroupDto,
      RtkqRequest<{
        itemPrice: DeleteItemPriceArgs;
        paging: Paging;
        filters?: PriceMatrixFilterParams;
      }>
    >({
      queryFn: (args) => {
        return rtkq(args).exec(() =>
          pricingHttp.deleteItemPrice(args.itemPrice),
        );
      },
      invalidatesTags: ['personalization-item-price'],
      onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
        const { data: group } = await queryFulfilled;
        dispatch(
          pricingApi.util.updateQueryData(
            'getPriceMatrix',
            {
              communityId: args.itemPrice.communityId,
              menuId: args.itemPrice.menuId,
              paging: args.paging,
              filters: args.filters,
            },
            (draft) => {
              removePriceMatrixRowPrices(draft, args.itemPrice);
              updatePriceMatrixData(draft, group);
            },
          ),
        );
      },
    }),

    savePricingMarkup: build.mutation<void, RtkqRequest<SavePricingMarkupArgs>>(
      {
        queryFn: (args) => {
          return rtkq(args).exec(() => pricingHttp.saveMarkup(args));
        },
        invalidatesTags: (_1, _2, args) => [
          { type: 'pricing-markup', id: args.communityId },
          { type: 'personalization-item-price' },
          { type: 'special-request-price' },
        ],
        onQueryStarted: async (
          { communityId, categoryId, value, menuId },
          { dispatch, queryFulfilled },
        ) => {
          await queryFulfilled;
          dispatch(
            pricingApi.util.updateQueryData(
              'getPricingSettingsMatrix',
              { communityId, menuId },
              (data) => {
                const item = data.rows.find(
                  (x) => x.categories.at(-1)!.id === categoryId,
                )!;
                item.value = value;
                data.id = data.id ?? 'id';
              },
            ),
          );
        },
      },
    ),

    deletePricingMarkup: build.mutation<
      void,
      RtkqRequest<DeletePricingMarkupArgs>
    >({
      queryFn: (args) => {
        return rtkq(args).exec(() => pricingHttp.deleteMarkup(args));
      },
      invalidatesTags: (_1, _2, args) => [
        { type: 'pricing-markup', id: args.communityId },
        { type: 'personalization-item-price' },
        { type: 'special-request-price' },
      ],
      onQueryStarted: async (
        { communityId, categoryId, menuId },
        { dispatch, queryFulfilled },
      ) => {
        await queryFulfilled;
        dispatch(
          pricingApi.util.updateQueryData(
            'getPricingSettingsMatrix',
            { communityId, menuId },
            (data) => {
              const item = data.rows.find(
                (x) => x.categories.at(-1)!.id === categoryId,
              )!;
              item.value = undefined;
            },
          ),
        );
      },
    }),

    getPricingSettingsMatrix: build.query<
      PricingSettingsMatrixState,
      RtkqRequest<{
        communityId: string;
        menuId?: string;
      }>
    >({
      queryFn: async (args) => {
        return rtkq(args).exec(() =>
          pricingHttp.getSettingsMatrix(args.communityId, args.menuId),
        );
      },
      providesTags: (result, _, __) => {
        const { rows = [] } = result ?? {};
        return [
          { type: 'category', id: 'list' },
          ...rows.flatMap((x) =>
            x.categories.map((c) => ({
              type: 'category' as const,
              id: c.id,
            })),
          ),
        ];
      },
    }),

    updateMarkupDefaults: build.mutation<
      void,
      RtkqRequest<UpdatePricingMarkupDefaultsArgs>
    >({
      queryFn: (args) => {
        return rtkq(args).exec(() => pricingHttp.updateMarkupDefaults(args));
      },
      invalidatesTags: (_1, _2, args) => [
        { type: 'pricing-markup', id: args.communityId },
        { type: 'personalization-item-price' },
        { type: 'special-request-price' },
      ],
      onQueryStarted: async (
        { communityId, constructionMarkup, residentMarkup, salesTax, menuId },
        { dispatch, queryFulfilled },
      ) => {
        await queryFulfilled;
        dispatch(
          pricingApi.util.updateQueryData(
            'getPricingSettingsMatrix',
            { communityId, menuId },
            (data) => {
              data.defaultMarkups = {
                construction: constructionMarkup ?? 0,
                resident: residentMarkup ?? 0,
                salesTax: salesTax ?? 0,
              };
              data.id = data.id ?? 'id';
            },
          ),
        );
      },
    }),

    getSpecialRequestPriceMatrix: build.query<
      SpecialRequestPriceMatrixState,
      RtkqRequest<{
        projectIds?: string[];
        communityId: string;
        paging: Paging;
        sorting?: SortResult;
        filters?: SpecialRequestPriceMatrixFilterParams;
      }>
    >({
      queryFn: (args) => {
        return rtkq(args).exec(() =>
          pricingHttp.getSpecialRequestMatrix(
            args.paging,
            args.communityId,
            args.projectIds,
            args.filters,
            args.sorting,
          ),
        );
      },
      providesTags: (result, _, { projectIds, communityId }) => {
        const rows = result?.rows.items || [];
        const projects = projectIds ?? [];

        return [
          ...rows.flatMap((x) =>
            x.categories.map((c) => ({ type: 'category' as const, id: c.id })),
          ),
          ...rows.map((x) => ({ type: 'home' as const, id: x.home.id })),
          ...rows.map((x) => ({ type: 'special-request' as const, id: x.id })),
          ...projects.map((x) => ({
            type: 'special-request' as const,
            id: 'project-' + x,
          })),
          { type: 'pricing-markup', id: communityId },
        ];
      },
    }),

    updateSpecialRequestPriceMatrixRow: build.mutation<
      UpdateSpecialRequestPriceMatrixRowResult,
      RtkqRequest<UpdateSpecialRequestPriceMatrixRowArgs>
    >({
      queryFn: (args) => {
        return rtkq(args).exec(() =>
          pricingHttp.updateSpecialRequestPriceMatrixRow(args),
        );
      },
      invalidatesTags: (_, __, { id }) => [
        { type: 'special-request', id },
        { type: 'special-request-price', id },
      ],
      onQueryStarted: async (
        { projectIds, communityId, id, paging },
        { dispatch, queryFulfilled },
      ) => {
        try {
          const result = await queryFulfilled;

          dispatch(
            pricingApi.util.updateQueryData(
              'getSpecialRequestPriceMatrix',
              { projectIds: projectIds, communityId, paging },
              (draft) => {
                const item = draft.rows.items.find((x) => x.id === id)!;
                item.prices = result.data.prices;
              },
            ),
          );
        } catch {}
      },
    }),

    updateItemMenuItemPricingInfo: build.mutation<
      ItemPriceGroupDto,
      RtkqRequest<{
        itemPrice: UpdateItemMenuItemPricingInfoArgs;
        paging: Paging;
      }>
    >({
      queryFn: (args) =>
        rtkq(args).exec(() =>
          pricingHttp.updateItemPricingInfo(args.itemPrice),
        ),
      invalidatesTags: (_, __, { itemPrice }) => [
        { type: 'personalization-item-price' },
        { type: 'itemMenu', id: itemPrice.menuId },
      ],
      onQueryStarted: async (
        { itemPrice, paging },
        { dispatch, queryFulfilled },
      ) => {
        const { data } = await queryFulfilled;

        dispatch(
          pricingApi.util.updateQueryData(
            'getPriceMatrix',
            {
              communityId: itemPrice.communityId,
              menuId: itemPrice.menuId,
              paging,
            },
            (draft) => {
              draft.rows.items
                .filter((x) => x.itemId === itemPrice.id)
                .forEach((item) => {
                  item.itemType = itemPrice.type;
                  item.standardCredit = itemPrice.standardCredit;
                });

              updatePriceMatrixData(draft, data);
            },
          ),
        );
      },
    }),

    copyPricing: build.mutation<
      void,
      RtkqRequest<{ fromMenuId: string; toMenuId: string }>
    >({
      queryFn: (args) => rtkq(args).exec(() => pricingHttp.copyPricing(args)),
      invalidatesTags: (_, __, args) => [
        { type: 'itemMenu' as const, id: args.toMenuId },
      ],
    }),

    getHomePriceMatrix: build.query<
      HomePriceMatrixState,
      RtkqRequest<{
        homeIds: string[];
        paging: Paging;
        filters?: HomePriceMatrixFilterParams;
      }>
    >({
      queryFn: async (args) => {
        return rtkq(args).exec(() =>
          pricingHttp.getHomeMatrix(args.homeIds, args.paging, args.filters),
        );
      },
      providesTags: (result) => {
        const rows = result?.rows.items || [];

        return [
          { type: 'personalization-item-price' },
          ...rows.map((x) => ({ type: 'item' as const, id: x.itemId })),
          ...rows.flatMap((x) =>
            x.categories.map((c) => ({ type: 'category' as const, id: c.id })),
          ),
          ...rows.map((x) => ({
            type: 'home' as const,
            id: x.home.id ?? 'none',
          })),
          ...rows
            .filter((x) => x.room != null)
            .map((x) => ({ type: 'room' as const, id: x.room!.id })),
        ];
      },
    }),

    saveHomeItemPrice: build.mutation<void, RtkqRequest<SaveHomeItemPriceArgs>>(
      {
        queryFn: (args) => {
          return rtkq(args).exec(() => pricingHttp.saveHomeItemPrice(args));
        },
        invalidatesTags: (_, __, { itemId }) => [
          { type: 'personalization-item-price' },
          { type: 'item', id: itemId },
        ],
      },
    ),
  }),
});

export const {
  useGetPriceMatrixQuery,
  useSaveItemPriceMutation,
  useDeleteItemPriceMutation,
  useSavePricingMarkupMutation,
  useDeletePricingMarkupMutation,
  useGetPricingSettingsMatrixQuery,
  useUpdateMarkupDefaultsMutation,
  useGetSpecialRequestPriceMatrixQuery,
  useUpdateSpecialRequestPriceMatrixRowMutation,
  useUpdateItemMenuItemPricingInfoMutation,
  useCopyPricingMutation,
  useGetHomePriceMatrixQuery,
  useSaveHomeItemPriceMutation,
} = pricingApi;
