import {
  Modal,
  Button,
  Col,
  Alert,
  Divider,
  Typography,
  Space,
  Switch,
} from 'antd';
import { Form, StyledRow } from '@/components';
import { useTranslation } from 'react-i18next';
import {
  ItemListNav,
  useItemNavSelectedState,
  ItemDetailsPanel,
  useFormikSelectableSuboption,
  ItemListNavProps,
  ItemDetailsPanelProps,
} from '@/views';
import {
  CategoryState,
  ItemState,
  SubOptionState,
  useGetItemMenuItemsQuery,
  useGetPersonalizationAvailableItemsQuery,
  useGetPersonalizationAvailableSubOptionsQuery,
  useGetPersonalizationCategoriesQuery,
  useGetSubOptionsListQuery,
} from '@/redux';
import * as Yup from 'yup';
import { ClippoAction } from '@webinex/clippo';
import { isEmpty } from 'lodash';
import { useEffect, useMemo } from 'react';
import { useFormikContext } from 'formik';
import { array, useBool } from '@/utils';

export interface PersonalizationItemFormValue {
  id: string;
  itemId: string;
  suboptionId: string | undefined;
  notes: string | undefined;
  attachments: ClippoAction[];
}

export interface AddPersonalizationItemFormValue
  extends PersonalizationItemFormValue {
  __hasSuboptions: boolean;
}

interface Props {
  onClose: () => any;
  onSubmit: (value: PersonalizationItemFormValue) => any;
  initialValue: PersonalizationItemFormValue;
  communityId: string;
  floorplanPersonalization: boolean;
  homeId: string;
  projectId: string;
  roomId?: string;
  stepId?: string;
  noItemSelection: boolean;
  head: React.ReactNode;
  texts: {
    title: React.ReactNode;
    saveBtn: React.ReactNode;
  };
  onlyGeoItems?: boolean;
}

const schema = Yup.object().shape({
  __hasSuboptions: Yup.bool(),
  itemId: Yup.string().nullable().required({ key: 'item.required' }),
  suboptionId: Yup.string()
    .nullable()
    .when('__hasSuboptions', {
      is: true,
      then: (schema) => schema.required({ key: 'suboption.required' }),
    }),
  notes: Yup.string().field('lg').nullable(),
});

function useSelectableSuboption(
  selectedSubOptionIds?: string[],
  itemId?: string,
) {
  const formik = useFormikContext<AddPersonalizationItemFormValue>();

  const { currentData: suboptions } = useGetSubOptionsListQuery(
    {
      itemId: itemId!,
      includedUnlistedSuboptions: selectedSubOptionIds,
    },
    { skip: !itemId },
  );

  const selectableSuboption = useFormikSelectableSuboption('suboptionId');

  useEffect(() => {
    formik.setFieldValue('__hasSuboptions', !isEmpty(suboptions));

    if (formik.values.suboptionId) {
      return;
    }

    if (!isEmpty(suboptions) && suboptions!.length === 1) {
      formik.setFieldValue('suboptionId', suboptions!.at(0)!.id);
    } else if (isEmpty(selectedSubOptionIds)) {
      formik.setFieldValue('suboptionId', null);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [suboptions]);

  return selectableSuboption;
}

function ItemListNavLocal(
  props: Omit<ItemListNavProps, 'dataSource'> & {
    homeId: string;
    projectId: string;
    floorplanPersonalization: boolean;
    roomId?: string;
    stepId?: string;
  },
) {
  const { homeId, projectId, roomId, stepId, floorplanPersonalization } = props;
  const { data: items = array.empty<ItemState>() } =
    useGetPersonalizationAvailableItemsQuery({
      projectId,
      homeId,
      roomId,
      stepId,
    });

  const { data: categories = array.empty<CategoryState>() } =
    useGetPersonalizationCategoriesQuery({
      homeId,
      projectId,
      roomId,
      stepId,
      floorplanPersonalization,
    });

  const dataSource = useMemo(
    () => ({ items, categories }),
    [items, categories],
  );

  return <ItemListNav {...props} dataSource={dataSource} />;
}

interface ItemDetailsLocalProps extends ItemDetailsPanelProps {
  homeId: string;
  projectId: string;
}

function ItemListDetailsLocal(props: ItemDetailsLocalProps) {
  const {
    id,
    selectableSuboption,
    includeUnlistedSuboptions,
    homeId,
    projectId,
  } = props;
  const { data = array.empty<SubOptionState>() } =
    useGetPersonalizationAvailableSubOptionsQuery({
      homeId,
      projectId,
      itemId: props.id,
      includeUnlisted: includeUnlistedSuboptions,
    });
  const { data: itemMenuItems } = useGetItemMenuItemsQuery({
    projectId,
    homeId,
  });
  const itemMenuItem = itemMenuItems?.find((x) => x.id === id);

  return (
    <ItemDetailsPanel
      id={id}
      selectableSuboption={selectableSuboption}
      includeUnlistedSuboptions={includeUnlistedSuboptions}
      subOptions={data}
      readonly
      itemMenuItemType={itemMenuItem?.type}
    />
  );
}

function FormContent(props: Props) {
  const {
    communityId,
    homeId,
    projectId,
    stepId,
    roomId,
    noItemSelection,
    head,
    onlyGeoItems,
    initialValue,
    floorplanPersonalization,
  } = props;
  const { t } = useTranslation();
  const formik = useFormikContext<AddPersonalizationItemFormValue>();
  const { itemId } = formik.values;

  const selectedSubOptionIds = useMemo(
    () => (initialValue.suboptionId ? [initialValue.suboptionId] : []),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const selected = useItemNavSelectedState();
  const selectableSuboption = useSelectableSuboption(
    selectedSubOptionIds,
    itemId,
  );

  const [expandAll, setExpandAll, toggleExpandAll] = useBool(false);

  return (
    <>
      {head}

      <StyledRow className="mt-4">
        {!noItemSelection && (
          <Col span={6}>
            <Space>
              <Typography.Title level={4}>
                {t('personalization.item.details.categories')}
              </Typography.Title>
              <Switch
                checked={expandAll}
                className="mb-2"
                onChange={toggleExpandAll}
                checkedChildren={t('personalization.item.details.collapse')}
                unCheckedChildren={t('personalization.item.details.expand')}
              />
            </Space>

            <ItemListNavLocal
              {...selected}
              communityId={communityId}
              selectedId={itemId}
              onSelected={(id) => formik.setFieldValue('itemId', id, true)}
              isGeo={onlyGeoItems}
              homeId={homeId!}
              projectId={projectId!}
              stepId={stepId}
              roomId={roomId}
              floorplanPersonalization={floorplanPersonalization}
              expandAll={expandAll}
              onExpand={setExpandAll}
            />
          </Col>
        )}
        <Col
          offset={!noItemSelection ? 1 : 0}
          span={!noItemSelection ? 17 : 24}
        >
          {itemId && (
            <ItemListDetailsLocal
              id={itemId}
              projectId={projectId}
              homeId={homeId}
              selectableSuboption={selectableSuboption}
              includeUnlistedSuboptions={selectedSubOptionIds}
              readonly
            />
          )}
        </Col>
      </StyledRow>

      {itemId && (
        <>
          <StyledRow>
            <Col
              offset={!noItemSelection ? 7 : 0}
              span={!noItemSelection ? 17 : 24}
            >
              <Divider />
            </Col>
          </StyledRow>
          <StyledRow>
            <Col
              offset={!noItemSelection ? 7 : 0}
              span={!noItemSelection ? 12 : 24}
            >
              <Typography.Title level={4} className="pb-2">
                {t('personalization.item.details.notes')}
              </Typography.Title>
              <Form.TextArea name="notes" label={false} />
              <Form.DeferAttach
                name="attachments"
                noPreload={!initialValue.id}
                ownerId={initialValue.id}
                ownerType="PersonalizationAttachments"
              />
            </Col>
          </StyledRow>
        </>
      )}

      <Form.ErrorMessage name="itemId">
        {(error) => <Alert className="mt-3" type="error" description={error} />}
      </Form.ErrorMessage>
      <Form.ErrorMessage name="suboptionId">
        {(error) => <Alert className="mt-3" type="error" description={error} />}
      </Form.ErrorMessage>
    </>
  );
}

export function PersonalizationItemDetailsModal(props: Props) {
  const { onClose, onSubmit, texts, initialValue: value } = props;
  const { t } = useTranslation();

  return (
    <Modal
      width={'80%'}
      title={texts.title}
      visible
      onCancel={onClose}
      footer={[
        <Button key="back" type="default" onClick={onClose}>
          {t('personalization.item.details.cancelButton')}
        </Button>,
        <Form.Submit
          uid="personalization-item-details"
          key="submit"
          type="primary"
        >
          {texts.saveBtn}
        </Form.Submit>,
      ]}
    >
      <Form.Formik<AddPersonalizationItemFormValue>
        uid="personalization-item-details"
        i18n="personalization.item.details"
        initialValues={{ ...value, __hasSuboptions: false }}
        validationSchema={schema}
        onSubmit={onSubmit}
      >
        <FormContent {...props} />
      </Form.Formik>
    </Modal>
  );
}
