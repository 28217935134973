import { NavLink, TableColumnType } from '@/components';
import { CommunityState } from '@/redux';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { StringFilterOptions } from '@/core';
import { useFiltersFactory } from '@/utils';
import { LogoUploadItem } from './LogoUploadItem';

export function useCommunityLogosColumns(
  communities: CommunityState[] | undefined,
  communityFilterOptions?: StringFilterOptions,
) {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'summarySettings.communityLogos',
  });

  const filters = useFiltersFactory(communities, communityFilterOptions);

  return useMemo<TableColumnType<CommunityState>[]>(() => {
    const columns: Array<TableColumnType<CommunityState> | false> = [
      {
        title: t('client'),
        key: 'clientName',
        sorter: (a, b) => a.clientName.localeCompare(b.clientName),
        render: (_, { clientId, clientName }) => (
          <NavLink to={`/clients/${clientId}`}>{clientName}</NavLink>
        ),
        ...filters.select((x) => x.clientName, 'clientName'),
        width: '25%',
      },
      {
        title: t('community'),
        key: 'name',
        sorter: (a, b) => a.name.localeCompare(b.name),
        render: (_, { id, name }) => (
          <NavLink to={`/communities/${id}`}>{name}</NavLink>
        ),
        ...filters.select((x) => x.name, 'name'),
        width: '25%',
      },
      {
        title: t('logo'),
        key: 'logo',
        render: (_, { id, logo }) => (
          <LogoUploadItem communityId={id} reference={logo} />
        ),
      },
    ];

    return columns.filter((x) => !!x) as TableColumnType<CommunityState>[];
  }, [t, filters]);
}
