import { enumObject, EnumValue } from '@/utils';
import { CategoryLookupDto } from '../http.categories';
import { FloorplanLookupDto } from '../http.floorplans';
import { HomeLookupDto } from '../http.homes';
import { ItemMenuItemType } from '../http.itemMenus';
import { ItemPricePer } from '../http.items';
import { RoomLookupDto } from '../http.rooms';
import { SpecialRequestStatus } from '../http.specialRequests';
import { SubcontractorLookupDto } from '../http.subcontractors';
import { Paged } from '../http/paged';
import { CommonFilterType, ResidentLookupDto } from '..';

const PRICE_MATRIX_TYPE_VALUES = ['Constant', 'Variable'] as const;

export type PriceMatrixType = EnumValue<typeof PRICE_MATRIX_TYPE_VALUES>;

// eslint-disable-next-line
export const PriceMatrixType = enumObject(
  'PriceMatrixType',
  PRICE_MATRIX_TYPE_VALUES,
);

const SALE_TAX_APPLY_TO = ['OwnerPrice', 'ResidentPrice'] as const;

export type SaleTaxApplyTo = EnumValue<typeof SALE_TAX_APPLY_TO>;

// eslint-disable-next-line
export const SaleTaxApplyTo = enumObject('SaleTaxApplyTo', SALE_TAX_APPLY_TO);

export interface PricingValue {
  baseCost: number;
  ownerPrice?: ItemPriceAmount;
  residentPrice?: ItemPriceAmount;
}

export interface PriceMatrixDto {
  rows: Paged<PriceMatrixRowDto>;
}

export interface PriceMatrixRowDto {
  itemId: string;
  itemName: string;
  itemPricePer: ItemPricePer;
  itemType: ItemMenuItemType;
  standardCredit: boolean;
  floorplan?: FloorplanLookupDto;
  room?: RoomLookupDto;
  categories: CategoryLookupDto[];
  prices?: ItemPriceSettingsValue;
}

export interface HomePriceMatrixDto {
  rows: Paged<HomePriceMatrixRowDto>;
}

export interface ItemPriceSettingsValue extends PricingValue {}

export interface ItemPriceAmount {
  value: number;
  isOverriden: boolean;
}

export interface SaveItemPriceDto {
  communityId: string;
  itemId: string;
  menuId: string;
  floorplanId?: string;
  roomId?: string;
  baseCost: number;
  ownerPrice?: number;
  residentPrice?: number;
}

export interface DeleteItemPriceDto {
  communityId: string;
  itemId: string;
  menuId: string;
  floorplanId?: string;
  roomId?: string;
}

export interface SavePricingMarkupDto {
  communityId: string;
  categoryId: string;
  menuId: string | undefined;
  value: PricingMarkup;
}

export interface PricingMarkup {
  construction?: number;
  resident?: number;
  saleTax?: SaleTax;
}

export interface SaleTax {
  value: number;
  applyTo?: SaleTaxApplyTo[];
}

export interface DeletePricingMarkupDto {
  communityId: string;
  categoryId: string;
  menuId: string | undefined;
}

export interface UpdatePricingMarkupDefaultsDto {
  communityId: string;
  menuId: string | undefined;
  constructionMarkup?: number;
  residentMarkup?: number;
  salesTax?: number;
}

export interface ItemPriceSettingsDto {
  clientId: string;
  communityId: string;
  itemId: string;
  menuId: string;
  floorplanId?: string;
  roomId?: string;
  value: ItemPriceSettingsValue;
}

export interface ItemPriceGroupDto {
  clientId: string;
  communityId: string;
  menuId: string;
  prices: ItemPriceSettingsDto[];
}

export interface PricingMarkupDefaultsDto {
  construction?: number;
  resident?: number;
  salesTax?: number;
}

export interface PricingSettingsMatrixDto {
  id?: string;
  defaultMarkups: PricingMarkupDefaultsDto;
  rows: PricingSettingsMatrixRowDto[];
}

export interface PricingSettingsMatrixRowDto {
  value?: PricingMarkup;
  categories: CategoryLookupDto[];
}

export interface SpecialRequestPrices extends PricingValue {}

export interface SpecialRequestPriceMatrixDto {
  rows: Paged<SpecialRequestPriceMatrixRowDto>;
}

export interface SpecialRequestPriceMatrixRowDto {
  id: string;
  projectId: string;
  name: string;
  fee: boolean;
  status: SpecialRequestStatus;
  prices?: SpecialRequestPrices;
  home: HomeLookupDto;
  homeConstructionNumber?: string;
  subcontractor?: SubcontractorLookupDto;
  categories: CategoryLookupDto[];
  pmLiteEnabled?: boolean;
  residents?: ResidentLookupDto[];
  isFinalized: boolean;
  number?: number;
}

export interface UpdateSpecialRequestPrices {
  baseCost: number;
  ownerPrice?: number;
  residentPrice?: number;
}

export interface UpdateSpecialRequestPriceMatrixRowDto {
  id: string;
  status: SpecialRequestStatus;
  fee: boolean;
  subcontractorId?: string;
  prices?: UpdateSpecialRequestPrices;
}

export interface UpdateSpecialRequestPriceMatrixRowResultDto {
  id: string;
  prices?: SpecialRequestPrices;
}

export interface UpdateItemMenuItemPricingInfoDto {
  id: string;
  menuId: string;
  type: ItemMenuItemType;
  standardCredit: boolean;
}

export interface PricingNotCompletedPayload {
  tbdItems: PricingNotCompletedPayloadItem[];
  notInMenuItems: PricingNotCompletedPayloadItem[];
  tbdSpecialRequests: PricingNotCompletedPayloadItem[];
  invalidStatusSpecialRequests: PricingNotCompletedPayloadItem[];
}

export interface PricingNotCompletedPayloadItem {
  name: string | null;
  number: string;
}

export interface HomePricingValue {
  baseCost?: ItemPriceAmount;
  ownerPrice?: ItemPriceAmount;
  residentPrice?: ItemPriceAmount;
}

export interface HomePriceMatrixRowDto
  extends Omit<PriceMatrixRowDto, 'prices'> {
  home: HomeLookupDto;
  prices?: HomePricingValue;
  isGeo: boolean;
}

export interface SaveHomeItemPriceDto {
  communityId: string;
  itemId: string;
  homeId: string;
  roomId?: string;
  baseCost?: number;
  ownerPrice?: number;
  residentPrice?: number;
}

export interface PriceMatrixFilterParams extends CommonFilterType {
  category?: string[];
  subcategory1?: string[];
  subcategory2?: string[];
  itemName?: string[];
  itemType?: ItemMenuItemType[];
  floorplan?: string[];
  room?: string[];
}

export interface HomePriceMatrixFilterParams extends CommonFilterType {
  category?: string[];
  subcategory1?: string[];
  subcategory2?: string[];
  home?: string[];
  itemName?: string[];
  itemType?: string[];
  floorplan?: string[];
  room?: string[];
}

export interface SpecialRequestPriceMatrixFilterParams
  extends CommonFilterType {
  category?: string[];
  subcategory1?: string[];
  subcategory2?: string[];
  home?: string[];
  name?: string[];
  subcontractor?: string[];
  status?: string[];
  numberFrom?: string;
  numberTo?: string;
}
