import { EnumValue, enumObject } from '@/utils';
import { ClippoAction } from '@webinex/clippo';
import { CategoryLookupDto } from '../http.categories';
import { GeoItemPosition } from '../http.personalization';
import { ProjectStepLookupDto } from '../http.projects';
import { RoomLookupDto } from '../http.rooms';
import { Role } from '../http.users';

export interface SpecialRequestPricesDto {
  baseCost?: number;
  ownerPrice?: number;
  residentPrice?: number;
}

export interface SpecialRequestDto {
  id: string;
  name: string;
  step: ProjectStepLookupDto;
  category: SpecialRequestCategoryDto;
  description?: string;
  imageActions?: ClippoAction[];
  status: SpecialRequestStatus;
  projectId: string;
  homeId: string;
  isFloorplan: boolean;
  room?: RoomLookupDto;
  fee: boolean;
  prices?: SpecialRequestPricesDto;
  hasAnyComment: boolean;
  position?: GeoItemPosition;
  number?: number;
  asItem?: boolean;
}

export interface AddSpecialRequestDto {
  homeId: string;
  projectId: string;
  name: string;
  stepId: string;
  categoryId: string;
  description?: string;
  imageActions?: ClippoAction[];
  roomId?: string;
  isFloorplan: boolean;
  fee: boolean;
  position?: GeoItemPosition;
  asItem?: boolean;
}

export interface UpdateSpecialRequestDto extends AddSpecialRequestDto {
  id: string;
  status: SpecialRequestStatus;
}

export interface SpecialRequestCategoryDto {
  category: CategoryLookupDto;
  root: CategoryLookupDto;
  path: CategoryLookupDto[];
}

const SPECIAL_REQUEST_STATUS_VALUES = [
  'Pending',
  'Clarify',
  'Denied',
  'ManagementApproved',
  'Drawing',
  'Price',
  'ConstructionApproved',
  'ResidentDeclined',
] as const;
export type SpecialRequestStatus = EnumValue<
  typeof SPECIAL_REQUEST_STATUS_VALUES
>;
// eslint-disable-next-line
export const SpecialRequestStatus = enumObject(
  'SpecialRequestStatus',
  SPECIAL_REQUEST_STATUS_VALUES,
);

export interface SpecialRequestCommentDto {
  id: string;
  content: string;
  createdAt: string;
  createdByName: string;
  createdById: string;
  specialRequestId: string;
}

export interface SpecialRequestStatusHistoryDto {
  id: string;
  status: SpecialRequestStatus;
  createdAt: string;
  createdByName: string;
  createdById: string;
  createdByRole: Role;
  specialRequestId: string;
}

export type AddSpecialRequestCommentDto = Omit<
  SpecialRequestCommentDto,
  'id' | 'createdAt' | 'createdByName' | 'createdById'
>;

export type UpdateSpecialRequestCommentDto = Omit<
  SpecialRequestCommentDto,
  'createdAt' | 'createdByName' | 'createdById' | 'specialRequestId'
>;

export interface SpecialRequestLookupDto {
  id: string;
  name: string;
}
