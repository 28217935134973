import React, { memo, useCallback, useRef, useState } from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import styles from './ImageBox.module.scss';
import { useTranslation } from 'react-i18next';
import { LazyImageCropper } from '@/views/Users.General/EditAvatar/LazyImageCropper';
import { useBlob } from '@/views/Users.General/EditAvatar/UserAvatarEdit';
import { AttachmentDto } from '@/core';
import { fileListToArray, validate } from './ImageUpload';

export interface ImageCropUploadProps {
  onUpload: (files: File[]) => any;
  onRemove?: (item: AttachmentDto) => any;
  items?: AttachmentDto[];
  acceptPdf?: boolean;
  aspect?: number;
}

function useHandleCropUpload(props: ImageCropUploadProps) {
  const { onUpload: onUploadProp, acceptPdf, onRemove, items } = props;
  const [uploading, setUploading] = useState(false);
  const [file, setFile] = useState<File | undefined>(undefined);
  const blob = useBlob(file);

  const onCancel = () => {
    setFile(undefined);
    setUploading(false);
  };

  const onChangeFn = (e: React.ChangeEvent<HTMLInputElement>) => {
    let files = fileListToArray(e.target.files!);
    files = files.filter((file) => validate(file, acceptPdf));
    setUploading(true);
    if (files.length === 1) {
      setFile(files[0]);
    }
  };

  const isReplace = onRemove && items && items!.length > 0;
  const onSave = (file: File) => {
    isReplace && Promise.resolve(onRemove(items[0])).finally();
    Promise.resolve(onUploadProp([file])).finally(() => setUploading(false));
    setFile(undefined);
  };

  const onCropUpload = useCallback(onChangeFn, [acceptPdf]);
  return { onCropUpload, uploading, blob, onCancel, onSave, file };
}

function _ImageCropUpload(props: ImageCropUploadProps) {
  const { aspect } = props;
  const { t } = useTranslation();
  const inputRef = useRef<HTMLInputElement>(null);
  const { onCropUpload, blob, uploading, onCancel, onSave } =
    useHandleCropUpload(props);
  const handleClick = useCallback(() => inputRef.current?.click(), [inputRef]);

  return (
    <div className={styles.upload} onClick={handleClick}>
      <div className={styles.uploadPlaceholder}>
        {uploading ? (
          <LoadingOutlined />
        ) : (
          <div className={styles.uploadText}>
            <div className="plus">+</div>
            <div>{t('upload')}</div>
          </div>
        )}
      </div>
      <input
        disabled={uploading}
        ref={inputRef}
        type="file"
        value=""
        accept={props.acceptPdf ? 'image/*, application/pdf' : 'image/*'}
        multiple
        onChange={onCropUpload}
      />
      <>
        {blob && (
          <LazyImageCropper
            blob={blob}
            onCancel={onCancel}
            onSave={onSave}
            aspect={aspect ?? 1}
          />
        )}
      </>
    </div>
  );
}

export const ImageCropUpload = memo(_ImageCropUpload);
