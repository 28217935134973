import {
  AddPathToProjectDto,
  ProjectPathDetailsDto,
  ProjectPathDto,
  UpdateProjectDeadlineDto,
  ProjectStepLookupDto,
  UpdateProjectDto,
  UpdateProjectPathDto,
  UpdateProjectStatusDto,
  ProjectContextDto,
  ProjectHomeInfoDto,
  UpdateProjectHomeFloorplanImageDto,
  ItemMenuItemDto,
  ProjectHomeDeadlineInfoDto,
  ProjectHomeStepDeadlineLookupDto,
  Paged,
  Paging,
  ProjectFilterParams,
  ProjectLocationDeadlineInfoDto,
  ProjectLocationStepDeadlineLookupDto,
  LocationDeadlineFilterParams,
  ProjectHomeDeadlineFilterParams,
} from '@/core';
import {
  ProjectDto,
  CreateProjectDto,
  ProjectDetailsDto,
  projectsHttp,
} from '@/core';
import { api } from '../api';
import { rtkq, RtkqRequest } from '../rtkq';
import { tree } from '@/utils';
import { isArray } from 'lodash';
import { SortResult } from '@/components/Collections.Paging/useSorting';

export type ProjectState = ProjectDto;
export type ProjectDetailsState = ProjectDetailsDto;
export type CreateProjectArgs = CreateProjectDto;
export type UpdateProjectArgs = UpdateProjectDto;
export type UpdateProjectStatusArgs = UpdateProjectStatusDto;
export type AddPathToProjectArgs = AddPathToProjectDto;
export type ProjectStepLookupState = ProjectStepLookupDto;
export type UpdateProjectHomesArgs = { projectId: string; homeIds: string[] };
export type ProjectPathDetailsState = ProjectPathDetailsDto;
export type UpdateProjectPathArgs = UpdateProjectPathDto;
export type ProjectPathState = ProjectPathDto;
export type UpdateProjectDeadlinesArgs = UpdateProjectDeadlineDto;
export type ProjectContext = ProjectContextDto;
export type ProjectHomeInfo = ProjectHomeInfoDto;
export type UpdateProjectHomeFloorplanImageArgs =
  UpdateProjectHomeFloorplanImageDto;
export type ProjectHomeDeadlineInfo = ProjectHomeDeadlineInfoDto;
export type UpdateProjectHomeStepDeadlineArgs =
  ProjectHomeStepDeadlineLookupDto;
export type ProjectLocationDeadlineInfo = ProjectLocationDeadlineInfoDto;
export type UpdateProjectLocationStepDeadlineArgs =
  ProjectLocationStepDeadlineLookupDto;

export const projectsApi = api.injectEndpoints({
  endpoints: (build) => ({
    getProjectsList: build.query<
      Paged<ProjectState>,
      RtkqRequest<{
        clientId?: string;
        communityId?: string | string[];
        isActive?: boolean;
        paging: Paging;
        filters?: ProjectFilterParams;
        sorting?: SortResult;
      }>
    >({
      queryFn: async (args) => {
        return rtkq(args).exec(() => projectsHttp.getAll(args));
      },
      providesTags: (result) => [
        { type: 'project', id: 'list' },
        ...(result?.items || []).map(({ id }) => ({
          type: 'project' as const,
          id,
        })),
        ...(result?.items || [])
          .map((project) =>
            project.homeIds.map((id) => ({ type: 'home' as const, id })),
          )
          .flat(),
      ],
    }),

    getProject: build.query<ProjectDetailsState, RtkqRequest<{ id: string }>>({
      queryFn: async (args) => {
        const { id } = args;
        return await rtkq(args).exec(() => projectsHttp.get(id));
      },
      providesTags: (project) => [
        { type: 'project', id: project?.id ?? 'none' },
        { type: 'project-path', id: project?.id ?? 'none' },
        ...(project?.homeIds.map((id) => ({ type: 'home' as const, id })) ??
          []),
      ],
    }),

    getItemMenuItems: build.query<
      ItemMenuItemDto[],
      RtkqRequest<{ projectId: string; homeId: string }>
    >({
      queryFn: async (args) => {
        const { projectId, homeId } = args;
        return rtkq(args).exec(() =>
          projectsHttp.getMenuItems(projectId, homeId),
        );
      },
      providesTags: (_, __, { homeId }) => [{ type: 'home', id: homeId }],
    }),

    createProject: build.mutation<string, RtkqRequest<CreateProjectArgs>>({
      queryFn: async (args) => {
        return await rtkq(args).exec(() => projectsHttp.create(args));
      },
      invalidatesTags: (_, __, args) => [
        { type: 'project', id: 'list' },
        { type: 'community', id: 'list' },
        ...args.homeIds.map((id) => ({ type: 'home' as const, id })),
      ],
    }),

    updateProject: build.mutation<void, RtkqRequest<UpdateProjectArgs>>({
      queryFn: async (args) => {
        return await rtkq(args).exec(() => projectsHttp.update(args));
      },
      invalidatesTags: (_, __, args) => [
        { type: 'project', id: args.id },
        ...args.homeIds.map((id) => ({ type: 'home' as const, id })),
      ],
    }),

    updateProjectStatus: build.mutation<
      void,
      RtkqRequest<UpdateProjectStatusArgs>
    >({
      queryFn: async (args) => {
        return await rtkq(args).exec(() => projectsHttp.updateStatus(args));
      },
      invalidatesTags: (_, __, args) => [
        { type: 'project', id: args.id },
        { type: 'projectContext', id: args.id },
      ],
    }),

    addPathToProject: build.mutation<void, RtkqRequest<AddPathToProjectArgs>>({
      queryFn: async (args) => {
        return await rtkq(args).exec(() => projectsHttp.addPath(args));
      },
      invalidatesTags: (_, __, args) => [
        { type: 'project', id: args.projectId },
        { type: 'project-path', id: args.projectId },
      ],
    }),

    updateProjectHomes: build.mutation<
      void,
      RtkqRequest<UpdateProjectHomesArgs>
    >({
      queryFn: async (args) => {
        return await rtkq(args).exec(async () => {
          await projectsHttp.updateHomes(args.projectId, args.homeIds);
        });
      },
      invalidatesTags: (_, __, args) => [
        { type: 'project', id: args.projectId },
        ...args.homeIds.map((id) => ({ type: 'home' as const, id })),
        ...args.homeIds.map((id) => ({ type: 'projectHomeInfo' as const, id })),
        { type: 'project', id: 'project_homes' },
        { type: 'project-tasks', id: args.projectId },
        { type: 'project-homes-steps', id: args.projectId },
      ],
    }),

    updateDeadlines: build.mutation<
      void,
      RtkqRequest<UpdateProjectDeadlinesArgs>
    >({
      queryFn: async (args) => {
        return await rtkq(args).exec(() => projectsHttp.updateDeadlines(args));
      },
      invalidatesTags: (_, __, args) => [
        { type: 'project', id: args.id },
        { type: 'projectHomeInfo', id: args.id },
        { type: 'project-homes-steps', id: args.id },
      ],
    }),

    unlistProject: build.mutation<void, RtkqRequest<{ id: string }>>({
      queryFn: async (args) => {
        return await rtkq(args).exec(() => projectsHttp.unlist(args.id));
      },
      invalidatesTags: (_, __, args) => [
        { type: 'project', id: args.id },
        { type: 'community', id: 'list' },
      ],
    }),

    getProjectSteps: build.query<
      ProjectStepLookupState[],
      RtkqRequest<{ ids?: string | string[] }>
    >({
      queryFn: async (args) =>
        await rtkq(args).exec(() => projectsHttp.getSteps(args.ids)),
      providesTags: (_, __, { ids }) => {
        if (isArray(ids)) {
          return ids.map((id) => ({ type: 'project', id: id }));
        }
        return [{ type: 'project', id: ids }];
      },
    }),

    getProjectPath: build.query<ProjectPathState, RtkqRequest<{ id: string }>>({
      queryFn: async (args) => {
        const { id } = args;
        return await rtkq(args).exec(() => projectsHttp.getPath(id));
      },
      providesTags: (projectPath) => [
        { type: 'project-path', id: projectPath?.projectId ?? 'none' },
        ...(projectPath?.steps
          .flatMap((x) => x.categories)
          .map((category) => ({ type: 'category' as const, id: category })) ??
          []),
      ],
    }),

    getProjectPathDetails: build.query<
      ProjectPathDetailsState,
      RtkqRequest<{ projectId: string }>
    >({
      queryFn: async (args) => {
        const { projectId } = args;
        return await rtkq(args).exec(() =>
          projectsHttp.getPathDetails(projectId),
        );
      },
      providesTags: (projectPath) => {
        if (!projectPath) {
          return [{ type: 'project-path' as const, id: 'none' }];
        }

        return [
          { type: 'project-path' as const, id: projectPath.projectId },

          ...tree
            .flatten(projectPath.steps.flatMap((x) => x.selectedCategories))
            .map((category) => ({
              type: 'category' as const,
              id: category.id,
            })),
        ];
      },
    }),

    updateProjectPath: build.mutation<void, RtkqRequest<UpdateProjectPathArgs>>(
      {
        queryFn: (args) => {
          return rtkq(args).exec(() => projectsHttp.updatePath(args));
        },
        invalidatesTags: (_, __, args) => [
          { type: 'project-path', id: args.projectId },
          { type: 'project', id: args.projectId },
          { type: 'personalizationItem-category', id: 'list' },
          { type: 'personalizationItem', id: 'list' },
          { type: 'projectHomeInfo', id: args.projectId },
        ],
      },
    ),

    deleteProjectPath: build.mutation<void, RtkqRequest<{ projectId: string }>>(
      {
        queryFn: (args) => {
          return rtkq(args).exec(() => projectsHttp.deletePath(args.projectId));
        },
        invalidatesTags: (_, __, args) => [
          { type: 'project', id: args.projectId },
          { type: 'project-path', id: args.projectId },
          { type: 'personalizationItem-category', id: 'list' },
          { type: 'personalizationItem', id: 'list' },
        ],
      },
    ),

    getProjectContext: build.query<ProjectContext, RtkqRequest<{ id: string }>>(
      {
        queryFn: async (args) => {
          const { id } = args;
          return await rtkq(args).exec(() => projectsHttp.getContext(id));
        },
        providesTags: (context) => [
          { type: 'projectContext', id: context?.projectId ?? 'none' },
        ],
      },
    ),

    getProjectHomeInfo: build.query<
      ProjectHomeInfo,
      RtkqRequest<{ id: string; homeId: string }>
    >({
      queryFn: async (args) => {
        return await rtkq(args).exec(() =>
          projectsHttp.getProjectHomeContext(args),
        );
      },
      providesTags: (context) => [
        { type: 'projectHomeInfo', id: context?.homeId ?? 'none' },
        { type: 'projectHomeInfo', id: context?.projectId ?? 'none' },
      ],
    }),

    updateProjectHomeFloorplanImage: build.mutation<
      void,
      RtkqRequest<UpdateProjectHomeFloorplanImageArgs>
    >({
      queryFn: async (args) => {
        return await rtkq(args).exec(() =>
          projectsHttp.updateProjectHomeFloorplanImage(args),
        );
      },
      invalidatesTags: (_, __, args) => [
        { type: 'home', id: 'personalization-' + args.homeId },
        { type: 'projectHomeInfo', id: args.homeId },
      ],
    }),

    getProjectsHomesDeadlinesInfo: build.query<
      Paged<ProjectHomeDeadlineInfo>,
      RtkqRequest<{
        projectId: string;
        paging: Paging;
        sorting?: SortResult;
        filters?: ProjectHomeDeadlineFilterParams;
      }>
    >({
      queryFn: async (args) => {
        return await rtkq(args).exec(() =>
          projectsHttp.getProjectsHomesDeadlinesInfo(args),
        );
      },
      providesTags: (_, __, args) => [
        { type: 'project-homes-steps', id: args.projectId },
      ],
    }),

    getProjectLocationDeadlinesInfo: build.query<
      Paged<ProjectLocationDeadlineInfo>,
      RtkqRequest<{
        projectId: string;
        paging: Paging;
        sorting?: SortResult;
        filters?: LocationDeadlineFilterParams;
      }>
    >({
      queryFn: async (args) => {
        return await rtkq(args).exec(() =>
          projectsHttp.getProjectLocationDeadlinesInfo(args),
        );
      },
      providesTags: (_, __, args) => [
        { type: 'project-homes-steps', id: args.projectId },
      ],
    }),

    updateProjectHomePathStepDeadline: build.mutation<
      void,
      RtkqRequest<UpdateProjectHomeStepDeadlineArgs>
    >({
      queryFn: async (args) => {
        return await rtkq(args).exec(() =>
          projectsHttp.updateProjectHomePathStepDeadline(args),
        );
      },
      invalidatesTags: (_, __, args) => [
        { type: 'home', id: 'personalization-' + args.homeId },
        { type: 'project-homes-steps', id: args.projectId },
        { type: 'project', id: args.projectId },
        { type: 'projectHomeInfo', id: args.projectId },
      ],
    }),

    updateProjectLocationPathStepDeadline: build.mutation<
      void,
      RtkqRequest<UpdateProjectLocationStepDeadlineArgs>
    >({
      queryFn: async (args) => {
        return await rtkq(args).exec(() =>
          projectsHttp.updateProjectLocationPathStepDeadline(args),
        );
      },
      invalidatesTags: (_, __, args) => [
        { type: 'project-homes-steps', id: args.projectId },
        { type: 'project', id: args.projectId },
        { type: 'projectHomeInfo', id: args.projectId },
      ],
    }),

    getProjectHomeSteps: build.query<
      ProjectHomeStepDeadlineLookupDto[],
      RtkqRequest<{ homeId: string; projectId: string }>
    >({
      queryFn: async (args) =>
        await rtkq(args).exec(() => projectsHttp.getProjectHomeSteps(args)),
      providesTags: (_, __, { projectId }) => [
        { type: 'projectHomeInfo', id: projectId },
      ],
    }),
  }),
});

export const {
  useGetProjectQuery,
  useLazyGetProjectQuery,
  useGetProjectsListQuery,
  useLazyGetProjectsListQuery,
  useUpdateProjectMutation,
  useUpdateProjectStatusMutation,
  useUpdateDeadlinesMutation,
  useUnlistProjectMutation,
  useCreateProjectMutation,
  useGetProjectStepsQuery,
  useLazyGetProjectStepsQuery,
  useUpdateProjectHomesMutation,
  useAddPathToProjectMutation,
  useGetProjectPathDetailsQuery,
  useGetProjectPathQuery,
  useUpdateProjectPathMutation,
  useDeleteProjectPathMutation,
  useGetProjectContextQuery,
  useGetProjectHomeInfoQuery,
  useUpdateProjectHomeFloorplanImageMutation,
  useGetItemMenuItemsQuery,
  useGetProjectsHomesDeadlinesInfoQuery,
  useUpdateProjectHomePathStepDeadlineMutation,
  useGetProjectHomeStepsQuery,
  useGetProjectLocationDeadlinesInfoQuery,
  useUpdateProjectLocationPathStepDeadlineMutation,
} = projectsApi;
