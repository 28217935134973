import styles from './ImageMarkerBox.module.scss';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Overlay } from '../Overlay';
import { ImageMarkerView } from './ImageMarkerView';
import { useTranslation } from 'react-i18next';
import { useBool, useClickOutside } from '@/utils';
import { ImageMarkerValue } from './ImageMarkerBox';
import { SingleImageMarkerView } from './SingleImageMarkerView';
import classNames from 'classnames';

export interface SingleImageMarkerBoxProps<T extends ImageMarkerValue> {
  marker: T;
  src: string | undefined;
}

function NoImage() {
  const { t } = useTranslation();
  return <div className={styles.container}>{t('imageMarkerBox.noImage')}</div>;
}

function _SingleImageMarkerBox<T extends ImageMarkerValue>(
  props: SingleImageMarkerBoxProps<T>,
) {
  const { src, marker } = props;
  const [expanded, , toggleExpanded] = useBool(false);
  const { onContainerClick, contentRef } = useClickOutside(toggleExpanded);

  if (!src) {
    return <NoImage />;
  }

  return (
    <div className={classNames(styles.singleContainer)}>
      <SingleImageMarkerView
        imageRef={contentRef}
        marker={marker}
        src={src}
        readonly
        onImageClick={toggleExpanded}
      />
      <Overlay visible={expanded} actions={['close']} onClose={toggleExpanded}>
        <div className={styles.imageView} onClick={onContainerClick}>
          <ImageMarkerView
            markers={[marker]}
            src={src}
            className={styles.previewView}
            imageRef={contentRef}
            readonly
          />
        </div>
      </Overlay>
    </div>
  );
}

export function SingleImageMarkerBox<T extends ImageMarkerValue>(
  props: SingleImageMarkerBoxProps<T>,
) {
  return (
    <DndProvider backend={HTML5Backend}>
      <_SingleImageMarkerBox {...props} />
    </DndProvider>
  );
}
