import { useTranslation } from 'react-i18next';
import { TabPanel } from '@/components';
import { CommunityLogosListPanel } from './CommunityLogosListPanel';

export function CommunityLogosListTabPanel() {
  const { t } = useTranslation();
  const title = t('summarySettings.communityLogos.title');

  return (
    <TabPanel min="auto">
      <TabPanel.Title>{title}</TabPanel.Title>
      <TabPanel.Body>
        <CommunityLogosListPanel />
      </TabPanel.Body>
    </TabPanel>
  );
}
