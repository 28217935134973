import {
  AddCommunityDto,
  CommunityDto,
  communitiesHttp,
  CommunityStatus,
  CommunityAssignmentDto,
  UpdateCommunityAssignmentDto,
  CommunityUserDto,
  UserCommunityAccessDetailsDto,
  CommunityContextDto,
  Paged,
  Paging,
  CommunitiesFilterParams,
  CommunityUserFilterParams,
} from '@/core';
import { api } from '../api';
import { rtkq, RtkqRequest } from '../rtkq';
import { SortResult } from '@/components/Collections.Paging/useSorting';

export type CommunityState = CommunityDto;
export type AddCommunityArgs = AddCommunityDto;
export type CommunityUserState = CommunityUserDto;
export type CommunityAssignmentArgs = CommunityAssignmentDto;
export type UpdateCommunityAssignmentArgs = UpdateCommunityAssignmentDto;
export type UserCommunityAccessDetails = UserCommunityAccessDetailsDto;
export type CommunityContext = CommunityContextDto;

export interface LocationState {
  id: string;
  name: string;
  children: LocationState[];
}

export const communitiesApi = api.injectEndpoints({
  endpoints: (build) => ({
    getCommunitiesList: build.query<
      Paged<CommunityState>,
      RtkqRequest<{
        clientId?: string[] | string;
        deactivated?: boolean | null;
        paging: Paging;
        filters?: CommunitiesFilterParams;
        sorters?: SortResult;
      }>
    >({
      queryFn: async (args) => {
        const { clientId, deactivated, paging, filters, sorters } = args;
        return await rtkq(args).exec(() =>
          communitiesHttp.getAll(
            paging,
            clientId,
            deactivated,
            filters,
            sorters,
          ),
        );
      },
      providesTags: (result) => [
        { type: 'community', id: 'list' },
        ...(result?.items || []).map(({ id }) => ({
          type: 'community' as const,
          id,
        })),
      ],
    }),

    getAvailableCommunitiesForProjectsList: build.query<
      CommunityState[],
      RtkqRequest<{
        clientId?: string;
      }>
    >({
      queryFn: async (args) => {
        const { clientId } = args;
        return await rtkq(args).exec(() =>
          communitiesHttp.getAvailableForProjects(clientId),
        );
      },
      providesTags: (result = []) => [
        { type: 'community', id: 'list' },
        ...result.map(({ id }) => ({ type: 'community' as const, id })),
      ],
    }),

    getCommunityDetails: build.query<
      CommunityState,
      RtkqRequest<{ id: string }>
    >({
      queryFn: async (args) => {
        const { id } = args;
        return await rtkq(args).exec(() => communitiesHttp.get(id));
      },
      providesTags: (community) => [
        { type: 'community', id: community?.id ?? 'none' },
      ],
    }),

    updateCommunity: build.mutation<void, RtkqRequest<CommunityState>>({
      queryFn: (args) => {
        return rtkq(args).exec(() => communitiesHttp.update(args));
      },
      invalidatesTags: (_, __, args) => [
        { type: 'community', id: 'list' },
        { type: 'community', id: args.id },
      ],
    }),

    addCommunity: build.mutation<string, RtkqRequest<AddCommunityArgs>>({
      queryFn: (args) => {
        return rtkq(args).exec(() => communitiesHttp.create(args));
      },
      invalidatesTags: () => [
        { type: 'community', id: 'list' },
        { type: 'last-created-community', id: 'list' },
      ],
    }),

    changeStatusCommunity: build.mutation<
      string,
      RtkqRequest<{ id: string; status: CommunityStatus }>
    >({
      queryFn: (args) => {
        const { id, status } = args;
        return rtkq(args).exec(() => communitiesHttp.changeStatus(id, status));
      },
      invalidatesTags: (_, __, args) => [
        { type: 'community', id: 'list' },
        { type: 'community', id: args.id },
      ],
    }),

    updateCommunityLogo: build.mutation<
      string,
      RtkqRequest<{ id: string; logo?: string }>
    >({
      queryFn: (args) => {
        const { id, logo } = args;
        return rtkq(args).exec(() => communitiesHttp.updateLogo(id, logo));
      },
      invalidatesTags: (_, __, args) => [
        { type: 'community', id: 'list' },
        { type: 'community', id: args.id },
      ],
    }),

    getLocations: build.query<
      LocationState[],
      RtkqRequest<{ communityId: string; includeUnlistedIds?: string[] }>
    >({
      queryFn: (args) => {
        const { communityId, includeUnlistedIds } = args;
        return rtkq(args).exec(() =>
          communitiesHttp.getLocations(communityId, includeUnlistedIds),
        );
      },
      providesTags: (result = [], __, { communityId }) => [
        { type: 'location', id: 'community-' + communityId },
        ...result.map(({ id }) => ({ type: 'location' as const, id })),
      ],
    }),

    updateLocation: build.mutation<
      void,
      RtkqRequest<{ communityId: string; location: LocationState }>
    >({
      queryFn: (args) => {
        const { communityId, location } = args;
        return rtkq(args).exec(() =>
          communitiesHttp.updateLocation(communityId, location),
        );
      },
      invalidatesTags: (_, __, { communityId, location }) => {
        return [
          { type: 'location', id: 'community-' + communityId },
          { type: 'location', id: location.id },
        ];
      },
    }),

    createLocation: build.mutation<
      void,
      RtkqRequest<{ communityId: string; location: LocationState }>
    >({
      queryFn: (args) => {
        const { communityId, location } = args;

        return rtkq(args).exec(() =>
          communitiesHttp.createLocation(communityId, location),
        );
      },
      invalidatesTags: (_, __, { communityId }) => {
        return [{ type: 'location', id: 'community-' + communityId }];
      },
    }),

    deleteLocation: build.mutation<
      void,
      RtkqRequest<{ communityId: string; id: string }>
    >({
      queryFn: (args) => {
        const { communityId, id } = args;
        return rtkq(args).exec(() =>
          communitiesHttp.unlistLocation(communityId, id),
        );
      },
      invalidatesTags: (_, __, { communityId, id }) => {
        return [
          { type: 'location', id: 'community-' + communityId },
          { type: 'location', id },
        ];
      },
    }),

    getCommunityUsers: build.query<
      Paged<CommunityUserState>,
      RtkqRequest<{
        communityId: string;
        paging: Paging;
        filters?: CommunityUserFilterParams;
        sorting?: SortResult;
      }>
    >({
      queryFn: (args) => {
        const { communityId, paging, filters, sorting } = args;
        return rtkq(args).exec(() =>
          communitiesHttp.getAllUsers(communityId, paging, filters, sorting),
        );
      },
      providesTags: (result) => [
        { type: 'communityUser', id: 'list' },
        ...(result?.items || []).map(({ id }) => ({
          type: 'communityUser' as const,
          id,
        })),
      ],
    }),

    assignToCommunity: build.mutation<
      string,
      RtkqRequest<CommunityAssignmentArgs>
    >({
      queryFn: (args) => {
        return rtkq(args).exec(() => communitiesHttp.assignUser(args));
      },
      invalidatesTags: (_, __, args) => [
        { type: 'communityUser', id: 'list' },
        { type: 'task', id: 'responsible-persons' },
        { type: 'communityUser', id: args.userId },
      ],
    }),

    updateAssignToCommunity: build.mutation<
      void,
      RtkqRequest<UpdateCommunityAssignmentArgs>
    >({
      queryFn: (args) => {
        return rtkq(args).exec(() => communitiesHttp.updateCommunityUser(args));
      },
      invalidatesTags: (_, __, args) => [
        { type: 'communityUser', id: args.userId },
        { type: 'task', id: 'responsible-persons' },
      ],
    }),

    removeCommunityAssignment: build.mutation<
      void,
      RtkqRequest<{ communityId: string; userId: string }>
    >({
      queryFn: (args) => {
        return rtkq(args).exec(() =>
          communitiesHttp.removeUserAssignment(args),
        );
      },
      invalidatesTags: (_, __, args) => [
        { type: 'communityUser', id: args.userId },
        { type: 'task', id: 'responsible-persons' },
      ],
    }),

    getCommunityAccesses: build.query<
      UserCommunityAccessDetails[],
      RtkqRequest<{ userIds: string[] }>
    >({
      queryFn: (args) => {
        const { userIds } = args;
        return rtkq(args).exec(() => communitiesHttp.getAccesses(userIds));
      },
      providesTags: (result = []) => [
        ...result.map(({ userId }) => ({
          type: 'communityUser' as const,
          id: userId,
        })),
      ],
    }),

    getCommunityContext: build.query<
      CommunityContext,
      RtkqRequest<{ id: string }>
    >({
      queryFn: async (args) => {
        const { id } = args;
        return await rtkq(args).exec(() => communitiesHttp.getContext(id));
      },
      providesTags: (context) => [
        { type: 'communityContext', id: context?.communityId ?? 'none' },
        { type: 'community', id: context?.communityId ?? 'none' },
      ],
    }),

    getLastCreatedCommunity: build.query<
      CommunityState,
      RtkqRequest<{ clientId?: string }>
    >({
      queryFn: async (args) => {
        const { clientId } = args;
        return await rtkq(args).exec(() =>
          communitiesHttp.getLastCreated(clientId),
        );
      },
      providesTags: (result) => [
        { type: 'last-created-community', id: result?.clientId ?? 'none' },
      ],
    }),
  }),
});

export const {
  useGetCommunitiesListQuery,
  useLazyGetCommunitiesListQuery,
  useGetAvailableCommunitiesForProjectsListQuery,
  useLazyGetAvailableCommunitiesForProjectsListQuery,
  useAddCommunityMutation,
  useGetCommunityDetailsQuery,
  useLazyGetCommunityDetailsQuery,
  useUpdateCommunityMutation,
  useGetLocationsQuery,
  useUpdateLocationMutation,
  useCreateLocationMutation,
  useDeleteLocationMutation,
  useChangeStatusCommunityMutation,
  useGetCommunityUsersQuery,
  useAssignToCommunityMutation,
  useUpdateAssignToCommunityMutation,
  useRemoveCommunityAssignmentMutation,
  useGetCommunityAccessesQuery,
  useGetCommunityContextQuery,
  useGetLastCreatedCommunityQuery,
  useUpdateCommunityLogoMutation,
} = communitiesApi;
