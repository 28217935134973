import { useEffect, useMemo, useRef, useState } from 'react';
import { flippoClient } from '@/core';
import { Avatar } from '@/components';
import { LazyImageCropper } from './LazyImageCropper';

import styles from './UserAvatarEdit.module.scss';
import { ImagePicker } from './ImagePicker';
import { useAvatar } from '@/components/Avatar/useAvatar';
import { useBool } from '@/utils';

export interface UserAvatarEditProps {
  fullName: string;
  reference?: string;
  onChange(reference: string): any;
  size?: 'xs' | 'sm' | 'md' | 'lg' | number;
}

export function useBlob(file: File | undefined) {
  const previousBlobRef = useRef<string | undefined>(undefined);
  useEffect(() => cleanup, []);

  function cleanup() {
    previousBlobRef.current && URL.revokeObjectURL(previousBlobRef.current);
  }

  return useMemo(() => {
    cleanup();
    return file && (previousBlobRef.current = URL.createObjectURL(file));
  }, [file]);
}

function useAvatarState(props: UserAvatarEditProps) {
  const { onChange } = props;
  const [file, setFile] = useState<File | undefined>(undefined);
  const blob = useBlob(file);

  const onCancel = () => {
    setFile(undefined);
  };

  const onSave = (file: File) =>
    flippoClient
      .store(file)
      .then((reference) => onChange(reference))
      .then(() => setFile(undefined));

  return { blob, onSelect: setFile, onCancel, onSave };
}

export function UserAvatarEdit(props: UserAvatarEditProps) {
  const { fullName, reference, size } = props;
  const { blob, onSelect, onCancel, onSave } = useAvatarState(props);
  const { notFound } = useAvatar(reference);
  const [preview, , togglePreview] = useBool(false);

  return (
    <div className={styles.container}>
      <Avatar
        size={size}
        alt={fullName}
        reference={reference}
        preview={preview}
        onPreviewChange={togglePreview}
      />
      <ImagePicker
        onSelect={onSelect}
        notFound={notFound}
        onPreview={togglePreview}
      />
      {blob && (
        <LazyImageCropper
          blob={blob}
          onCancel={onCancel}
          onSave={onSave}
          aspect={1}
        />
      )}
    </div>
  );
}
