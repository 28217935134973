import { AddButton, dataNodeUtils, Icon, Tree } from '@/components';
import {
  CategoryState,
  ItemState,
  useGetCategoriesQuery,
  useGetItemsListQuery,
  useUserContextSelector,
} from '@/redux';
import { array, tree, useBool } from '@/utils';
import { DataNode } from 'antd/lib/tree';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ItemDeleteButton } from '../Items.Details';
import { useTranslation } from 'react-i18next';
import styles from './ItemListPanel.module.scss';
import classNames from 'classnames';
import { DeleteSubcategoryButton } from '../Items.Subcategories';
import { ItemIcon } from '../Items';
import { Assert, useCommunityContext } from '../Communities.Common';
import { CommunityPermissions } from '@/core';
import { Key } from 'antd/lib/table/interface';

const MAX_LEVEL = 2;

interface DataSource {
  items: ItemState[];
  categories: CategoryState[];
}

interface CustomDataNode extends DataNode {
  name: string;
}

export interface ItemListNavProps {
  dataSource?: DataSource;
  onSelected: (id: string | undefined) => any;
  selectedId: string | undefined;
  onSubcategorySelected: (id: string | undefined) => any;
  selectedSubcategoryId: string | undefined;
  onAdd?: (categoryId: string) => any;
  onAddSubcategory?: (categoryId: string) => any;
  onDeleted?: () => any;
  isGeo?: boolean;
  communityId: string;
  expandAll?: boolean;
  onExpand?: (value: boolean) => void;
}

export function useItemNavSelectedState(): Pick<
  ItemListNavProps,
  | 'onSelected'
  | 'selectedId'
  | 'onSubcategorySelected'
  | 'selectedSubcategoryId'
> {
  const [selectedId, onSelected] = useState<string>();
  const [selectedSubcategoryId, onSubcategorySelected] = useState<string>();
  return {
    selectedId,
    onSelected,
    onSubcategorySelected,
    selectedSubcategoryId,
  };
}

function useDataSource(props: ItemListNavProps) {
  const { communityId, isGeo, dataSource } = props;

  const { data: items = dataSource?.items || array.empty<ItemState>() } =
    useGetItemsListQuery(
      {
        communityId,
        isGeo,
      },
      { skip: !!dataSource },
    );

  const {
    data: categories = dataSource?.categories || array.empty<CategoryState>(),
  } = useGetCategoriesQuery(
    { communityId, listed: true },
    { skip: !!dataSource },
  );

  return useMemo(() => ({ items, categories }), [items, categories]);
}

function useTreeData(
  props: Omit<ItemListNavProps, 'dataSource'>,
  dataSource: DataSource,
) {
  const { t } = useTranslation();
  const { onDeleted, onAdd, onAddSubcategory } = props;

  const hasRole = useUserContextSelector((x) => x.hasRole);
  const { hasPermission } = useCommunityContext();
  const canAddSubcategory =
    hasRole('CLIENT_ADMIN') ||
    hasRole('BFP_ADMIN') ||
    hasPermission(CommunityPermissions.ItemsCategories.Manage);

  const { items, categories } = dataSource;

  return useMemo(() => {
    function mapCategory(
      category: CategoryState,
      level: number,
    ): CustomDataNode {
      const categoryChildren = category!.children
        ? category!.children.map((x) => mapCategory(x, level + 1))
        : [];

      const itemChildren = items!
        .filter((x) => x.categoryId === category.id)
        .map((item) => ({
          key: item.id,
          name: item.name,
          title: (
            <>
              {item.name}
              {onDeleted && (
                <Assert
                  permission={CommunityPermissions.ItemsCategories.Manage}
                  active
                >
                  <ItemDeleteButton id={item.id} onDeleted={onDeleted} />
                </Assert>
              )}
            </>
          ),
          className: 'item-child',
          icon: <ItemIcon />,
        }));
      const childrenNodes = [...categoryChildren, ...itemChildren].sort(
        (a, b) => a.name.localeCompare(b.name),
      );
      return {
        key: category.id,
        name: category.name,
        title: (
          <>
            {category.name}
            {category.custom && (
              <Assert
                permission={CommunityPermissions.ItemsCategories.Manage}
                active
              >
                <DeleteSubcategoryButton id={category.id} />
              </Assert>
            )}
          </>
        ),
        className: 'item-child',
        icon: (
          <>
            {canAddSubcategory && onAddSubcategory && level < MAX_LEVEL && (
              <Assert
                permission={CommunityPermissions.ItemsCategories.Manage}
                active
              >
                <AddButton
                  type="link"
                  title={t('items.addSubcategoryIconTitle')}
                  onClick={(event) => {
                    event.stopPropagation();
                    onAddSubcategory(category.id);
                  }}
                />
              </Assert>
            )}

            {onAdd && (
              <Assert
                permission={CommunityPermissions.ItemsCategories.Manage}
                active
              >
                <AddButton
                  type="link"
                  title={t('items.addIconTitle')}
                  onClick={(event) => {
                    event.stopPropagation();
                    onAdd(category.id);
                  }}
                />
              </Assert>
            )}
          </>
        ),
        children: childrenNodes,
      };
    }

    return categories!.map((category) => mapCategory(category, 0));
  }, [
    categories,
    items,
    onAdd,
    onAddSubcategory,
    onDeleted,
    canAddSubcategory,
    t,
  ]);
}

function findFirst(categories: CategoryState[], id: string) {
  return tree.findFirst(
    categories,
    id,
    (x) => x.id,
    (x) => x.children,
  );
}

export function ItemListNav(props: ItemListNavProps) {
  const {
    selectedId,
    onSelected: onChange,
    onSubcategorySelected,
    selectedSubcategoryId,
    expandAll,
    onExpand: setExpandAll,
  } = props;

  const dataSource = useDataSource(props);
  const treeData: DataNode[] = useTreeData(props, dataSource);
  const { items, categories } = dataSource;

  const { treeKeys, categoriesCount } = useMemo(() => {
    return {
      treeKeys: treeData
        .flatMap((x) => dataNodeUtils.flatten(x))
        .map((x) => x.key),
      categoriesCount: treeData
        .flatMap((x) => dataNodeUtils.flatten(x))
        .filter((x) => x.children).length,
    };
  }, [treeData]);

  const selectedKeys = useMemo(
    () =>
      selectedId
        ? [selectedId]
        : selectedSubcategoryId
        ? [selectedSubcategoryId]
        : [],

    [selectedId, selectedSubcategoryId],
  );

  const onSelect = (selectedKeysValue: React.Key[]) => {
    const key = selectedKeysValue[0].toString();
    const selectedItem = items.find((x) => x.id === key);
    const selectedSubcategory = findFirst(categories, key);
    onSubcategorySelected(selectedSubcategory?.id);
    onChange(selectedItem?.id);
  };

  const [expandedKeys, setExpandedKeys] = useState<Key[]>([]);
  const [expandedInTreeClick, setExpandedInTreeClick] = useBool(false);

  useEffect(() => {
    if (expandedInTreeClick) {
      // Reset to false after onExpand has been handled
      setExpandedInTreeClick(false);
    }
  }, [expandedInTreeClick, setExpandedInTreeClick]);

  useEffect(() => {
    if (expandAll !== undefined) {
      !expandedInTreeClick && setExpandedKeys(expandAll ? treeKeys : []);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [treeKeys, expandAll]);

  const onExpand = useCallback(
    (keys: Key[], info: { expanded: boolean }) => {
      if (info.expanded) {
        setExpandedKeys(keys);
        setExpandAll && setExpandAll(keys.length === categoriesCount);
      } else {
        setExpandedKeys((prevKeys) => prevKeys.filter((x) => keys.includes(x)));
        setExpandAll && setExpandAll(false);
      }
      setExpandedInTreeClick(true);
    },
    [setExpandedInTreeClick, setExpandAll, categoriesCount],
  );

  return (
    <Tree
      rootClassName={classNames('nav', styles.nav)}
      treeData={treeData}
      onSelect={onSelect}
      showIcon
      selectedKeys={selectedKeys}
      switcherIcon={<Icon type="arrow-down" />}
      expandedKeys={expandedKeys}
      onExpand={onExpand}
    />
  );
}
