import { Col } from 'antd';
import { FieldRow, Form } from '@/components';
import { GenerateHomeStatusReportArgs, useUserContextSelector } from '@/redux';
import {
  useClientLookupSource,
  useReportingLocationLookupSource,
  useReportingHomesLookupSource,
  useReportingCommunitiesLookupSource,
  useProjectStepLookupSource,
} from '@/views';
import { useFormikContext } from 'formik';
import { ReportingProjectSelect } from './ReportingProjectSelect';
import { isArray } from 'lodash';

export function HomeReportFilterForm() {
  const isBfp = useUserContextSelector((x) => x.isBFP);
  const isAdmin = useUserContextSelector((x) => x.isAdmin);
  const currentClientId = useUserContextSelector((x) => x.clientId);

  const { values } = useFormikContext<GenerateHomeStatusReportArgs>();
  const { clientId, communityId, projectId } = values;

  const clientsDataSource = useClientLookupSource();
  const communitiesDataSource = useReportingCommunitiesLookupSource(
    isBfp ? clientId || false : currentClientId!,
  );

  const homesDataSource = useReportingHomesLookupSource(communityId, projectId);
  const locationsDataSource = useReportingLocationLookupSource(
    communityId,
    projectId,
  );

  const stepsDataSource = useProjectStepLookupSource(
    isArray(projectId) ? projectId : [projectId],
    'subtitle',
  );

  return (
    <>
      {isBfp && (
        <FieldRow>
          <Col span={12}>
            <Form.AsyncSelect
              name="clientId"
              dataSource={clientsDataSource}
              mode="multiple"
            />
          </Col>
        </FieldRow>
      )}
      {isAdmin && (
        <FieldRow>
          <Col span={12}>
            <Form.AsyncSelect
              name="communityId"
              key={`${clientId}`}
              dataSource={communitiesDataSource}
              mode="multiple"
            />
          </Col>
        </FieldRow>
      )}
      <FieldRow>
        <Col span={12}>
          <ReportingProjectSelect name="projectId" communityId={communityId} />
        </Col>
      </FieldRow>
      <FieldRow>
        <Col span={12}>
          <Form.AsyncSelect
            key={`${projectId}`}
            name="stepId"
            dataSource={stepsDataSource}
            mode="multiple"
          />
        </Col>
      </FieldRow>
      <FieldRow>
        <Col span={12}>
          <Form.AsyncSelect
            key={`${communityId}${projectId}`}
            name="locationId"
            dataSource={locationsDataSource}
            mode="multiple"
          />
        </Col>
      </FieldRow>
      <FieldRow>
        <Col span={12}>
          <Form.AsyncSelect
            key={`${communityId}${projectId}`}
            name="homeId"
            dataSource={homesDataSource}
            mode="multiple"
          />
        </Col>
      </FieldRow>
    </>
  );
}
