import { enumObject, EnumValue } from '@/utils';
import { CommonFilterType, TreeNodeDto } from '@/core';
import { CustomLegalLanguageLookupState } from '@/redux/customLegalLanguage/customLegalLanguageApi';

const PROJECT_TYPE_VALUES = ['NewConstruction', 'Renovation'] as const;
export type ProjectType = EnumValue<typeof PROJECT_TYPE_VALUES>;
// eslint-disable-next-line
export const ProjectType = enumObject('ProjectType', PROJECT_TYPE_VALUES);

const PROJECT_STATUS_VALUES = [
  'NotStarted',
  'InProgress',
  'Canceled',
  'Completed',
] as const;
export type ProjectStatus = EnumValue<typeof PROJECT_STATUS_VALUES>;
// eslint-disable-next-line
export const ProjectStatus = enumObject('ProjectStatus', PROJECT_STATUS_VALUES);

export function getNextProjectStatuses(status: ProjectStatus): ProjectStatus[] {
  switch (status) {
    case 'NotStarted':
      return ['InProgress', 'Canceled'];
    case 'InProgress':
      return ['Completed', 'Canceled'];
    case 'Canceled':
    case 'Completed':
    default:
      throw new Error(
        `Unable to resolve next statuses for ProjectStatus ${status}`,
      );
  }
}

export function isFinalProjectStatus(status?: ProjectStatus) {
  if (!status) return false;
  return status === 'Completed' || status === 'Canceled';
}

export function isNotStartedProjectStatus(status?: ProjectStatus) {
  if (!status) return false;
  return status === 'NotStarted';
}

export const ActiveProjectStatuses: ProjectStatus[] = [
  'InProgress',
  'NotStarted',
];
export function isActiveProjectStatus(status: ProjectStatus | undefined) {
  if (!status) return false;
  return ActiveProjectStatuses.includes(status);
}

export interface ProjectDto {
  id: string;
  clientId: string;
  clientName?: string;
  communityId: string;
  communityName: string;
  name: string;
  number: string;
  type: ProjectType;
  status: ProjectStatus;
  createdAt: string;
  pmLiteEnabled: boolean;
  description: string | null | undefined;
  homeIds: string[];
  pathTemplateId?: string;
  pathName?: string;
  isInactiveCommunity?: boolean;
  hasManagePermissions: boolean;
}

export interface ProjectStepLookupDto {
  id: string;
  projectId: string;
  projectName: string;
  index: number;
  name: string;
  deadlineDate?: string;
}

export type ProjectHomeStepDeadlineLookupDto = Omit<
  ProjectStepLookupDto,
  'projectName'
> & {
  homeId: string;
  isFinalized?: boolean;
};

export type ProjectLocationStepDeadlineLookupDto = Omit<
  ProjectStepLookupDto,
  'projectName'
> & {
  locaionId: string;
};

export interface ProjectHomeLookupDto {
  id: string;
  homeNumber: string;
}

export interface ProjectHomeDeadlineInfoDto {
  projectId: string;
  pathName: string;
  home: ProjectHomeLookupDto;
  steps: ProjectHomeStepDeadlineLookupDto[];
}

export interface ProjectLocationDeadlineInfoDto {
  projectId: string;
  pathName: string;
  location: LocationLookup;
  steps: ProjectLocationStepDeadlineLookupDto[];
}

export interface LocationLookup {
  location1Name: string;
  location2Name?: string;
  location3Name?: string;
  locationId: string;
}

export interface AddPathToProjectDto {
  projectId: string;
  pathTemplateId: string;
}

export type UpdateProjectDto = Pick<
  ProjectDto,
  'id' | 'name' | 'type' | 'pmLiteEnabled' | 'description' | 'homeIds'
>;

export interface UpdateProjectStatusDto {
  id: string;
  status: ProjectStatus;
}

export type CreateProjectDto = Omit<UpdateProjectDto, 'id'>;

export type ProjectDetailsDto = ProjectDto;

export interface ProjectPathDto {
  projectId: string;
  name: string;
  organizationId: string;
  steps: ProjectPathStep[];
}

export interface UpdateProjectPathDto {
  projectId: string;
  name: string;
  steps: ProjectPathStep[];
}

export interface ProjectPathStep {
  id: string;
  name: string;
  categories: string[];
}

export type ProjectPathDetailsDto = Omit<ProjectPathDto, 'steps'> & {
  steps: ProjectPathStepDetailsDto[];
  communityId?: string;
  communityName?: string;
};

export interface ProjectPathStepDetailsDto {
  name: string;
  selectedCategories: TreeNodeDto[];
}

export interface UpdateProjectDeadlineDto {
  id: string;
  items: ProjectDeadlineItemDto[];
}

export interface ProjectDeadlineItemDto {
  stepId: string;
  date?: string;
}

export interface ProjectContextDto {
  projectId: string;
  status: ProjectStatus;
  communityId: string;
  type: ProjectType;
}

export interface ProjectHomeInfoDto {
  projectId: string;
  homeId: string;
  steps: ProjectHomeStepLookupDto[];
  floorplanId?: string;
  floorplanImageReference?: string;
  finalizedAt?: string;
  isActive: boolean;
  menuId?: string;
  customLegalLanguage?: CustomLegalLanguageLookupState;
}

interface ProjectHomeStepLookupDto {
  stepId: string;
  isFloorplanPersonalizationNeeded: boolean;
  isFinalized: boolean;
  finalizedAt?: string;
  isDeadlinePassed?: boolean;
  customLegalLanguage?: CustomLegalLanguageLookupState;
}

export interface UpdateProjectHomeFloorplanImageDto {
  projectId: string;
  homeId: string;
  attachmentId: string;
}

export interface ProjectFilterParams extends CommonFilterType {
  number?: string[];
  name?: string[];
  community?: string[];
  homeCountFrom?: string;
  homeCountTo?: string;
  createdFrom?: string;
  createdTo?: string;
  type?: string[];
  status?: string[];
  pmLite?: boolean[];
  path?: string[];
}

export interface LocationDeadlineFilterParams extends CommonFilterType {
  location1?: string[];
  location2?: string[];
  location3?: string[];
}

export interface ProjectHomeDeadlineFilterParams extends CommonFilterType {
  number?: string[];
  homeNumber?: string[];
}
