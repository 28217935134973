import { useTranslation } from 'react-i18next';
import { Tabs } from '@/components';
import { enumObject } from '@/utils';
import { CustomLegalLanguageListTabPanel } from '../CustomLegalLanguages.List';
import { SignaturesListTabPanel } from '../Signatures.List';
import { CommunityLogosListTabPanel } from '../CommunityLogos.List/CommunityLogosListTabPanel';

const TAB_KEYS = [
  'custom-legal-language',
  'signature',
  'community-logos',
] as const;

export const SummarySettingsTabContentKeys = enumObject(
  'SummarySettingsTabContentKeys',
  TAB_KEYS,
);

const { TabPane } = Tabs;

export const SummarySettingsTabContent = () => {
  const { t } = useTranslation();

  return (
    <Tabs tabKeys={SummarySettingsTabContentKeys} destroyInactiveTabPane>
      <TabPane
        tab={t('customLegalLanguage.title')}
        key={SummarySettingsTabContentKeys['custom-legal-language']}
      >
        <CustomLegalLanguageListTabPanel />
      </TabPane>

      <TabPane
        tab={t('summarySettings.signatures.title')}
        key={SummarySettingsTabContentKeys['signature']}
      >
        <SignaturesListTabPanel />
      </TabPane>
      <TabPane
        tab={t('summarySettings.communityLogos.title')}
        key={SummarySettingsTabContentKeys['community-logos']}
      >
        <CommunityLogosListTabPanel />
      </TabPane>
    </Tabs>
  );
};
