import { getYesNoValue, TableColumnType } from '@/components';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  mapSubcontractorLookup,
  PriceInput,
  useCommunityContext,
} from '@/views';
import { PricingSpecialRequestSubcontractor } from './PricingSpecialRequestSubcontractor';
import { PricingSpecialRequestStatusValue } from './PricingSpecialRequestStatusValue';
import { PricingSpecialRequestFee } from './PricingSpecialRequestFee';
import { useSpecialRequestPriceMatrixRowChangeHandlerFactory } from './useSpecialRequestPriceMatrixRowChangeHandlerFactory';
import { SpecialRequestPriceMatrixRowState } from '@/redux';
import { shallowEqual } from 'react-redux';
import { sort, useFiltersFactory } from '@/utils';
import {
  CommunityPermissions,
  Paging,
  pagingFrom,
  ResidentLookupDto,
  SpecialRequestStatus,
  StringFilterOptions,
} from '@/core';
import { Button } from 'antd';
import styles from './ItemNameLink.module.scss';
import { UsePagingResult } from '@/components/Collections.Paging';
import { NavLink } from 'react-router-dom';

export function useSpecialRequestPricingColumns(
  communityId: string,
  pricing: SpecialRequestPriceMatrixRowState[],
  collapseCost: boolean,
  onItemNameClick: (specialRequestId: string) => void,
  pagingResult: UsePagingResult,
  projectIds?: string[],
  stringFilterOptions?: StringFilterOptions,
) {
  const { t } = useTranslation();

  const paging = useMemo<Paging>(() => {
    return pagingFrom(pagingResult, true);
  }, [pagingResult]);

  const changeHandlerFactory =
    useSpecialRequestPriceMatrixRowChangeHandlerFactory(
      communityId,
      paging,
      projectIds,
    );
  const { hasPermission } = useCommunityContext();
  const userHasPricingManagePermission = hasPermission(
    CommunityPermissions.Pricing.Manage,
  );

  const filters = useFiltersFactory(pricing, stringFilterOptions);

  return useMemo<TableColumnType<SpecialRequestPriceMatrixRowState>[]>(() => {
    function getPrevRowValue(
      rowValue: SpecialRequestPriceMatrixRowState,
    ): SpecialRequestPriceMatrixRowState | undefined {
      const index = pricing.indexOf(rowValue);
      return index > 0 ? pricing.at(index - 1) : undefined;
    }

    function isPrevMatch(
      rowValue: SpecialRequestPriceMatrixRowState,
      by: (row: SpecialRequestPriceMatrixRowState) => any[],
    ) {
      const prevRow = getPrevRowValue(rowValue);
      if (!prevRow) return false;
      const current = by(rowValue);
      const prev = by(prevRow);
      return shallowEqual(current, prev);
    }

    function isPrevCategoryMatch(rowValue: SpecialRequestPriceMatrixRowState) {
      return isPrevMatch(rowValue, (x) => [
        x.categories[0].id,
        x.categories[1]?.id,
        x.categories[2]?.id,
      ]);
    }

    const columns: Array<
      TableColumnType<SpecialRequestPriceMatrixRowState> | false
    > = [
      {
        title: t('pricing.specialRequest.homeName'),
        key: 'home',
        ...filters.select((x) => x.home.name, 'homeName'),
        exportValue: (record) => record.home.name,
        render: (_, record) => {
          if (isPrevMatch(record, (x) => [x.home.id])) {
            return null;
          }

          return record.isFinalized ? (
            record.home.name
          ) : (
            <NavLink
              to={`/communities/${communityId}/homes/${record.home.id}#personalization`}
            >
              {record.home.name}
            </NavLink>
          );
        },
        width: '6%',
      },
      {
        title: t('pricing.specialRequest.homeConstructionNumber'),
        key: 'constructionNumber',
        ...filters.select(
          (x) => x.homeConstructionNumber,
          'constructionNumber',
        ),
        sorter: (a, b) =>
          sort(a.homeConstructionNumber, b.homeConstructionNumber),
        exportValue: (record) => record.homeConstructionNumber,
        render: (_, record) => {
          if (isPrevMatch(record, (x) => [x.home.id])) {
            return null;
          }

          return record.homeConstructionNumber;
        },
        width: '11%',
      },
      {
        title: t('pricing.specialRequest.resident'),
        key: 'resident',
        ...filters.select(
          ({ residents }) => residents?.map((r) => r.name).join(', '),
          'residentFilter',
          'include',
        ),
        sorter: (a, b) =>
          sort(getResidentsNames(a.residents), getResidentsNames(b.residents)),
        render: (_, { residents }) => getResidentsNames(residents),
        width: '9%',
      },
      {
        title: t('pricing.specialRequest.category'),
        key: 'category',
        ...filters.select((x) => x.categories[0].name, 'category'),
        exportValue: (record) => record.categories[0].name,
        render: (_, record) => {
          if (isPrevCategoryMatch(record)) {
            return null;
          }

          return record.categories[0].name;
        },
        width: '10%',
      },
      {
        title: t('pricing.specialRequest.subcategory1'),
        key: 'subcategory1',
        ...filters.select((x) => x.categories[1]?.name, 'subcategory1'),
        exportValue: (record) => record.categories[1]?.name,
        render: (_, record) => {
          if (isPrevCategoryMatch(record)) {
            return null;
          }

          return record.categories[1]?.name || '-';
        },
        width: '10%',
      },
      {
        title: t('pricing.specialRequest.subcategory2'),
        key: 'subcategory2',
        ...filters.select((x) => x.categories[2]?.name, 'subcategory2'),
        exportValue: (record) => record.categories[2]?.name,
        render: (_, record) => {
          if (isPrevCategoryMatch(record)) {
            return null;
          }

          return record.categories[2]?.name || '-';
        },
        width: '10%',
      },
      {
        title: t('pricing.specialRequest.itemName'),
        key: 'name',
        ...filters.select((x) => x.name, 'name'),
        exportValue: (record) => record.name,
        render: (_, item) =>
          item.isFinalized ? (
            item.name
          ) : (
            <Button
              type="link"
              onClick={() => onItemNameClick(item.id)}
              className={styles.link}
            >
              {item.name}
            </Button>
          ),
        width: '6%',
      },
      {
        title: t('pricing.specialRequest.number'),
        key: 'number',
        sorter: (a, b) => (a.number ?? 0) - (b.number ?? 0),
        ...filters.numberRange((x) => x.number),
        exportValue: (record) => record.number,
        render: (_, record) => record.number,
        width: '5%',
      },
      {
        title: t('pricing.specialRequest.subcontractor'),
        key: 'subcontractor',
        ...filters.select((x) => x.subcontractor?.name, 'subcontractor'),
        exportValue: (record) => record.subcontractor?.name!,
        render: (_, item) => (
          <PricingSpecialRequestSubcontractor
            communityId={communityId}
            onSubmit={changeHandlerFactory(item, 'subcontractorId')}
            initialSubcontractor={
              item.subcontractor?.id
                ? mapSubcontractorLookup(item.subcontractor)
                : undefined
            }
            disabled={item.isFinalized || !userHasPricingManagePermission}
          />
        ),
        width: '10%',
      },
      {
        title: t('pricing.specialRequest.status'),
        key: 'status',
        ...filters.enumSelect((x) => x.status, SpecialRequestStatus),
        width: '10%',
        render: (_, item) => (
          <PricingSpecialRequestStatusValue
            initialStatus={item.status}
            onSubmit={changeHandlerFactory(item, 'status')}
            disabled={item.isFinalized || !userHasPricingManagePermission}
          />
        ),
      },
      {
        title: t('pricing.specialRequest.fee'),
        key: 'fee',
        exportValue: (record) => getYesNoValue(record.fee, t),
        render: (_, item) => (
          <PricingSpecialRequestFee
            initialFee={item.fee}
            onSubmit={changeHandlerFactory(item, 'fee')}
            disabled={item.isFinalized || !userHasPricingManagePermission}
          />
        ),
        width: '6%',
      },
      !collapseCost &&
        userHasPricingManagePermission && {
          title: t('pricing.specialRequest.baseCost'),
          key: 'baseCost',
          exportValue: (record) =>
            record.prices?.baseCost == null
              ? t('pricing.tbd')
              : record.prices.baseCost,
          width: '10%',
          render: (_, item) => (
            <PriceInput
              disabled={item.isFinalized || !userHasPricingManagePermission}
              onSubmit={changeHandlerFactory(item, 'baseCost')}
              initialValue={item.prices?.baseCost}
              tbd={item.prices?.baseCost == null}
              resetOnInitialValueChange
              calculated={false}
            />
          ),
        },
      !collapseCost &&
        userHasPricingManagePermission && {
          title: t('pricing.specialRequest.ownerPrice'),
          key: 'ownerPrice',
          exportValue: (record) =>
            record.prices?.ownerPrice?.value == null
              ? t('pricing.tbd')
              : record.prices.ownerPrice.value,
          width: '10%',
          render: (_, item) => (
            <PriceInput
              disabled={
                item.isFinalized ||
                !userHasPricingManagePermission ||
                item.prices?.baseCost == null
              }
              onSubmit={changeHandlerFactory(item, 'ownerPrice')}
              initialValue={item.prices?.ownerPrice?.value}
              calculated={!item.prices?.ownerPrice?.isOverriden}
              tbd={item.prices?.ownerPrice?.value == null}
              resetOnInitialValueChange
            />
          ),
        },
      {
        title: t('pricing.specialRequest.residentPrice'),
        key: 'residentPrice',
        exportValue: (record) =>
          record.prices?.residentPrice?.value == null
            ? t('pricing.tbd')
            : record.prices.residentPrice.value,
        width: '10%',
        render: (_, item) => (
          <PriceInput
            disabled={
              item.isFinalized ||
              !userHasPricingManagePermission ||
              item.prices?.baseCost == null
            }
            onSubmit={changeHandlerFactory(item, 'residentPrice')}
            initialValue={item.prices?.residentPrice?.value}
            calculated={!item.prices?.residentPrice?.isOverriden}
            tbd={item.prices?.residentPrice?.value == null}
            resetOnInitialValueChange
          />
        ),
      },
    ];
    return columns.filter(
      (x) => !!x,
    ) as TableColumnType<SpecialRequestPriceMatrixRowState>[];
  }, [
    t,
    filters,
    collapseCost,
    userHasPricingManagePermission,
    pricing,
    onItemNameClick,
    communityId,
    changeHandlerFactory,
  ]);
}

function getResidentsNames(residents?: ResidentLookupDto[]) {
  return residents
    ?.map((x) => x.name)
    .sort((a, b) => a.localeCompare(b))
    .join(', ');
}
