import { guard } from '@/utils';
import { Http } from '../http';

export class MiscHttp extends Http {
  public async avatar(reference: string) {
    guard.notNull(reference, 'reference');

    const response = await this._axios({
      method: 'GET',
      url: `/api/avatar/${reference}`,

      responseType: 'blob',
    });

    const data: Blob = response.data;
    return data;
  }

  public async logo(reference: string) {
    guard.notNull(reference, 'reference');

    const response = await this._axios({
      method: 'GET',
      url: `/api/community/community-logo/${reference}`,

      responseType: 'blob',
    });

    const data: Blob = response.data;
    return data;
  }
}

export const miscHttp = new MiscHttp();
