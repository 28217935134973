import classNames from 'classnames';
import { useMemo } from 'react';
import { ImageMarker } from './ImageMarker';
import { ImageMarkerValue } from './ImageMarkerBox';
import styles from './ImageMarkerBox.module.scss';
import { ImageMarkerViewProps } from './ImageMarkerView';

export type SingleImageMarkerViewProps<T extends ImageMarkerValue> = Omit<
  ImageMarkerViewProps<T>,
  'markers'
> & {
  marker: T;
  onImageClick?: () => void;
};

export function SingleImageMarkerView<T extends ImageMarkerValue>(
  props: SingleImageMarkerViewProps<T>,
) {
  const { imageRef, src, marker, onDelete, onEdit, readonly, onImageClick } =
    props;

  const {
    backGroundXPosition,
    backGroundYPosition,
    markerXPosition,
    markerYPosition,
  } = useGetSingleMarkerViewProps<T>(marker);

  return (
    <div className={classNames(styles.viewContainer)}>
      <div
        ref={imageRef}
        className={classNames(styles.clickable, styles.singleView)}
        style={{
          backgroundImage: `url(${src})`,
          backgroundPosition: `left ${backGroundXPosition}% top ${backGroundYPosition}%`,
        }}
        onClick={onImageClick}
      >
        <img
          src={src}
          alt="Floorplan"
          style={{
            visibility: 'hidden',
            width: '100%',
          }}
        />
        <ImageMarker
          value={{
            ...marker,
            x: markerXPosition,
            y: markerYPosition,
          }}
          index={1}
          onDelete={onDelete}
          onEdit={onEdit}
          readonly={readonly}
        />
      </div>
    </div>
  );
}

function useGetSingleMarkerViewProps<T extends ImageMarkerValue>(marker: T) {
  return useMemo(
    () => ({
      backGroundXPosition: marker.x * 2 - 50 > 0 ? marker.x * 2 - 50 : 0,
      backGroundYPosition: marker.y * 2 - 50 > 0 ? marker.y * 2 - 50 : 0,
      markerXPosition: marker.x * 2 - 50 > 0 ? 50 : marker.x * 2,
      markerYPosition: marker.y * 2 - 50 > 0 ? 50 : marker.y * 2,
    }),
    [marker.x, marker.y],
  );
}
