import { CodedException } from '@/core';
import { createApi, fakeBaseQuery } from '@reduxjs/toolkit/query/react';

export const api = createApi({
  reducerPath: 'api',
  baseQuery: fakeBaseQuery<CodedException>(),
  tagTypes: [
    'settings',
    'category',
    'client',
    'userTableSettings',
    'userNotificationSettings',
    'community',
    'location',
    'communityUser',
    'developScopeItem',
    'task',
    'floorplan',
    'home',
    'project',
    'resident',
    'item',
    'booklet-link-attachment-id',
    'path-template',
    'personalizationItem',
    'personalizationStep-finalized',
    'personalizationItem-category',
    'personalizationAvailableItem',
    'floorplanPersonalizationStep',
    'room',
    'roomType',
    'subcontractor',
    'subOption',
    'task',
    'comments',
    'task-financial-info',
    'user',
    'context',
    'itemMenu',
    'communityContext',
    'project-path',
    'projectContext',
    'projectHomeInfo',
    'develop-scope-room',
    'personalization-item-room',
    'special-request',
    'report-templates',
    'subscription',
    'payments',
    'pricing',
    'pricing-markup',
    'special-request-comment',
    'personalization-item-price',
    'special-request-price',
    'project-tasks',
    'project-homes-steps',
    'task-template',
    'template-task',
    'existing-item',
    'custom-legal-language',
    'last-created-community',
    'signature',
    'special-request-status',
  ],
  endpoints: () => ({}),
});
